<template>
  <div class="general_container">
    <div class="row">
      <div class="col-sm-12 col-md-3 align-self-center">
        <b>{{ "Type" }} </b>
      </div>

      <div class="col align-self-center">
        <b-form-group
          v-slot="{ ariaDescribedby }"
          v-if="this.$route.query.category == 'Insurance Claim'"
        >
          <b-form-radio
            class="my-1"
            v-model="InsuranceType"
            :aria-describedby="ariaDescribedby"
            name="some-radios"
            value="Auto Insurance Claims"
            >Auto Insurance Claims
            <span
              v-b-tooltip.hover.right="{ variant: 'primary' }"
              title="Damage to a vehicle due to an accident, theft, vandalism, or other covered events"
              class="material-icons-outlined help-icon"
            >
              help
            </span></b-form-radio
          >
          <b-form-radio
            class="my-1"
            v-model="InsuranceType"
            :aria-describedby="ariaDescribedby"
            name="some-radios"
            value="Homeowner's Insurance Claims"
            >Homeowner's Insurance Claims
            <span
              v-b-tooltip.hover.right="{ variant: 'primary' }"
              title="Property damage caused by fire, natural disasters, burglary, or liability claims for injuries that occurred on their property"
              class="material-icons-outlined help-icon"
            >
              help
            </span></b-form-radio
          >
          <b-form-radio
            class="my-1"
            v-model="InsuranceType"
            :aria-describedby="ariaDescribedby"
            name="some-radios"
            value="Health Insurance Claims"
            >Health Insurance Claims
            <span
              v-b-tooltip.hover.right="{ variant: 'primary' }"
              title="Seek reimbursement for medical expenses covered by the health insurance policy"
              class="material-icons-outlined help-icon"
            >
              help
            </span></b-form-radio
          >
          <b-form-radio
            class="my-1"
            v-model="InsuranceType"
            :aria-describedby="ariaDescribedby"
            name="some-radios"
            value="Life Insurance Claims"
            >Life Insurance Claims
            <span
              v-b-tooltip.hover.right="{ variant: 'primary' }"
              title="Insured person passes away, their beneficiaries can make claims to receive the death benefit provided by the life insurance policy"
              class="material-icons-outlined help-icon"
            >
              help
            </span></b-form-radio
          >
          <b-form-radio
            class="my-1"
            v-model="InsuranceType"
            :aria-describedby="ariaDescribedby"
            name="some-radios"
            value="Disability Insurance Claims"
            >Disability Insurance Claims
            <span
              v-b-tooltip.hover.right="{ variant: 'primary' }"
              title="Individuals who are unable to work due to a covered disability, and the insurance provides them with a portion of their lost income"
              class="material-icons-outlined help-icon"
            >
              help
            </span></b-form-radio
          >
          <b-form-radio
            class="my-1"
            v-model="InsuranceType"
            :aria-describedby="ariaDescribedby"
            name="some-radios"
            value="Travel Insurance Claims"
            >Travel Insurance Claims
            <span
              v-b-tooltip.hover.right="{ variant: 'primary' }"
              title="Trip cancellations, medical emergencies while abroad, lost baggage, or travel-related accidents"
              class="material-icons-outlined help-icon"
            >
              help
            </span></b-form-radio
          >
        </b-form-group>

        <b-form-group v-slot="{ ariaDescribedby }" v-else>
          <b-form-radio
            class="my-1"
            @change="changeLetterType()"
            v-model="selectedType"
            :aria-describedby="ariaDescribedby"
            name="some-radios"
            value="Individual - Individual"
            >Individual - Individual</b-form-radio
          >
          <b-form-radio
            class="my-1"
            @change="changeLetterType()"
            v-model="selectedType"
            :aria-describedby="ariaDescribedby"
            name="some-radios"
            value="Individual - Organization"
            >Individual - Organization</b-form-radio
          >
          <b-form-radio
            class="my-1"
            @change="changeLetterType()"
            v-model="selectedType"
            :aria-describedby="ariaDescribedby"
            name="some-radios"
            value="Organization - Individual"
            >Organization - Individual</b-form-radio
          >
          <b-form-radio
            class="my-1"
            @change="changeLetterType()"
            v-model="selectedType"
            :aria-describedby="ariaDescribedby"
            name="some-radios"
            value="Organization - Organization"
            >Organization - Organization</b-form-radio
          >
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-3 align-self-center">
        <b>{{ "Jurisdiction" }}</b>
        <span
          id="tooltip-target-1"
          class="text-danger font-weight-bold cursor-pointer mx-1"
        >*</span>
        <b-tooltip
          target="tooltip-target-1"
          triggers="hover"
          placement="top"
          variant="secondary"
          id="tooltip1"
          title="Required"
        >
        </b-tooltip>
      </div>

      <div class="col align-self-center">
        <!-- <select
          class="form-select w-100 h-100 rounded"
          id="inputGroupSelect04"
          aria-label="Example select with button addon"
          v-model="country"
          placeholder="Country"
          v-validate="'required'"
          name="Country"
        >
          <option :value="item" :key="index" v-for="(item, index) in countries">
            {{ item }}
          </option>
        </select> -->

        <b-form-select
          class="form-select w-100 h-100 rounded"
          :class="{ 'text-muted': country === '' }"
          id="inputGroupSelect04"
          aria-label="Example select with button addon"
          v-model="country"
          v-validate="'required'"
          name="Country"
        >
          <b-form-select-option value="" disabled selected>
            {{ "Please Select a Country" }}
          </b-form-select-option>
          <b-form-select-option
            class="text-softblack"
            :value="item"
            :key="index"
            v-for="(item, index) in countries"
          >
            {{ item }}
          </b-form-select-option>
        </b-form-select>
        <span class="text-danger small">
          {{ errors.first("Country") }}
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-3 align-self-center">
        <b>{{ "Lawyer" }} </b>
        <span
          id="tooltip-target-2"
          class="text-danger font-weight-bold cursor-pointer mx-1"
        >*</span>
        <b-tooltip
          target="tooltip-target-2"
          triggers="hover"
          placement="top"
          variant="secondary"
          id="tooltip1"
          title="Required"
        >
        </b-tooltip>
      </div>

      <div class="col align-self-center">
        <b-form-input
          v-model="lawyer_name"
          placeholder="First Name"
          autocorrect="off"
          autocomplete="off"
          v-validate="'required'"
          type="text"
          name="First Name"
        ></b-form-input>
        <span class="text-danger small">
          {{ errors.first("First Name") }}
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <b>{{ "Law Firm Details" }}</b>
        <span
          id="tooltip-target-3"
          class="text-danger font-weight-bold cursor-pointer mx-1"
        >*</span>
        <b-tooltip
          target="tooltip-target-3"
          triggers="hover"
          placement="top"
          variant="secondary"
          id="tooltip1"
          title="Required"
        >
        </b-tooltip>
      </div>
    </div>

    <div class="card border shadow rounded p-2">
      <div class="row">
        <div class="col-sm-12 col-md-3 align-self-center">
          <b class="text-muted">{{ "Name" }} </b>
        </div>

        <div class="col align-self-center">
          <b-form-input
            v-model="law_firm_name"
            placeholder=""
            autocorrect="off"
            autocomplete="off"
            v-validate="'required'"
            type="text"
            name="Law Firm Name"
          ></b-form-input>
          <span class="text-danger small">
            {{ errors.first("Law Firm Name") }}
          </span>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-3 align-self-center">
          <b class="text-muted">{{ "Phone Number" }} </b>
        </div>

        <div class="col align-self-center">
          <b-form-input
            v-model="law_firm_phone_number"
            placeholder=""
            autocorrect="off"
            autocomplete="off"
            v-validate="'required|numeric'"
            type="text"
            name="Law Firm Contact Number"
          ></b-form-input>
          <span
            class="text-danger text-sm"
            v-show="errors.has('Law Firm Contact Number')"
            >{{
              errors.first(`Law Firm
            Contact Number`)
            }}</span
          >
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-3 align-self-center">
          <b class="text-muted">{{ "Email" }} </b>
        </div>

        <div class="col align-self-center">
          <b-form-input
            v-model="law_firm_email"
            placeholder=""
            autocorrect="off"
            autocomplete="off"
            v-validate="'required'"
            type="email"
            name="Email"
          ></b-form-input>

          <span class="text-danger text-sm" v-show="errors.has('Email')">{{
            errors.first("Email")
          }}</span>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-3 align-self-center">
          <b class="text-muted">{{ "Address" }} </b>
        </div>

        <div class="col align-self-center">
          <b-form-input
            v-model="law_firm_address"
            placeholder=""
            autocorrect="off"
            autocomplete="off"
            v-validate="'required'"
            type="text"
            name="Address Line 1"
            class="input-address"
          ></b-form-input>
          <b-form-input
            class="input-address"
            v-model="law_firm_address_2"
            placeholder=""
            autocorrect="off"
            autocomplete="off"
          ></b-form-input>
          <b-form-input
            class="input-address"
            v-model="law_firm_address_3"
            placeholder=""
            autocorrect="off"
            autocomplete="off"
          ></b-form-input>
          <span class="text-danger small">
            {{ errors.first("Address Line 1") }}
          </span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <b>
          {{ selectedType.startsWith('Individual - ')
            ? "Sender"
            : "Organization"
          }}{{ " Details" }}
        </b>
        <span
          id="tooltip-target-4"
          class="text-danger font-weight-bold cursor-pointer mx-1"
        >*</span>
        <b-tooltip
          target="tooltip-target-4"
          triggers="hover"
          placement="top"
          variant="secondary"
          id="tooltip1"
          title="Required"
        >
        </b-tooltip>
      </div>
    </div>
    <div class="card border shadow rounded p-2">
      <div class="row">
        <div class="col-sm-12 col-md-3 align-self-center">
          <b class="text-muted">
            {{ selectedType.startsWith('Individual - ')
              ? "Sender"
              : "Organization"
            }}{{ " Name" }}
          </b>
        </div>

        <div
          class="col-12 col-sm-3 col-xl-2"
          id="senderDetailsName"
          v-if="selectedType.startsWith('Individual - ')"
        >
          <select
            class="form-select w-100 rounded"
            id="inputGroupSelect04"
            aria-label="Example select with button addon"
            v-model="sender_type"
            v-validate="'required'"
            name="Sender Title"
          >
            <option
              :value="sender"
              :key="index"
              v-for="(sender, index) in sender_types"
            >
              {{ sender }}
            </option>
          </select>
        </div>

        <div class="col align-self-center">
          <b-form-input
            v-model="sender_name"
            :placeholder="
              selectedType.startsWith('Organization - ')
                ? 'Organization Name'
                : 'First Name & Last Name'
            "
            autocorrect="off"
            autocomplete="off"
            v-validate="'required'"
            type="text"
            name="Sender Name"
          ></b-form-input>
          <span class="text-danger small">
            {{ errors.first("Sender Name") }}
          </span>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-3 align-self-center">
          <b class="text-muted">
            {{ selectedType.startsWith('Individual - ')
              ? "Sender"
              : "Organization"
            }}{{ " Address" }}
          </b>
        </div>

        <div class="col align-self-center">
          <b-form-input
            class="input-address mt-0"
            v-model="sender_address_1"
            placeholder="Address Line 1"
            autocorrect="off"
            autocomplete="off"
            v-validate="'required'"
            type="text"
            name="Sender Address 1"
          ></b-form-input>
          <b-form-input
            class="input-address"
            v-model="sender_address_2"
            placeholder="Address Line 2"
            autocorrect="off"
            autocomplete="off"
            type="text"
          ></b-form-input>
          <b-form-input
            class="input-address"
            v-model="sender_address_3"
            placeholder="Address Line 3"
            autocorrect="off"
            autocomplete="off"
            type="text"
          ></b-form-input>
          <span class="text-danger small">
            {{ errors.first("Sender Address 1") }}
          </span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <b v-if="this.$route.query.category == 'Insurance Claim'"
          >{{ "Insurance Agency Details" }}
        </b>
        <b v-else>
          {{ !selectedType.endsWith(' - Organization')
            ? "Recipient Details"
            : "Recipient Organization"
          }}{{ " Details" }}
        </b>
        <span
          id="tooltip-target-5"
          class="text-danger font-weight-bold cursor-pointer mx-1"
        >*</span>
        <b-tooltip
          target="tooltip-target-5"
          triggers="hover"
          placement="top"
          variant="secondary"
          id="tooltip1"
          title="Required"
        >
        </b-tooltip>
      </div>
    </div>

    <div class="card border shadow rounded p-2">
      <div class="row">
        <div class="col-sm-12 col-md-3 align-self-center">
          <b
            class="text-muted"
            v-if="this.$route.query.category == 'Insurance Claim'"
            >{{ "Recipient Name" }}
          </b>

          <b
            class="text-muted"
            v-else-if="!selectedType.endsWith(' - Organization')"
            >{{ "Recipient Name" }}
          </b>
          <b class="text-muted" v-else>{{ "Organization Name" }} </b>
        </div>
        <div
          class="col-xs-12 col-sm-3 col-xl-2"
          id="receiverDetailsName"
          v-if="
            this.$route.query.category == 'Insurance Claim' ||
            !selectedType.endsWith(' - Organization')
          "
        >
          <select
            class="form-select w-100 rounded"
            id="inputGroupSelect04"
            aria-label="Example select with button addon"
            v-model="receiver_type"
            v-validate="'required'"
            name="Recipient Title"
          >
            <option
              :value="receiver"
              :key="index"
              v-for="(receiver, index) in receiver_types"
            >
              {{ receiver }}
            </option>
          </select>
        </div>

        <div class="col align-self-center">
          <b-form-input
            v-model="receiver_name"
            :placeholder="
              this.$route.query.category == 'Insurance Claim'
                ? 'First Name & Last Name'
                : selectedType.endsWith(' - Organization')
                ? 'Organization Name'
                : 'First Name & Last Name'
            "
            autocorrect="off"
            autocomplete="off"
            v-validate="'required'"
            type="text"
            name="Reciever Name"
          ></b-form-input>
          <span class="text-danger small">
            {{ errors.first("Reciever Name") }}
          </span>
        </div>
      </div>

      <div class="row" v-if="this.$route.query.category == 'Insurance Claim'">
        <div class="col-sm-12 col-md-3 align-self-center">
          <b class="text-muted">{{ "Insurance Agency Name" }} </b>
        </div>

        <div class="col align-self-center">
          <b-form-input
            v-model="insurance_agency"
            :placeholder="
              this.$route.query.category == 'Insurance Claim'
                ? 'Insurance Agency Name'
                : selectedType.endsWith(' - Organization')
                ? 'Organization Name'
                : 'First Name & Last Name'
            "
            autocorrect="off"
            autocomplete="off"
            v-validate="'required'"
            type="text"
            name="Agency Name"
          ></b-form-input>
          <span class="text-danger small">
            {{ errors.first("Agency Name") }}
          </span>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-3 align-self-center">
          <b
            class="text-muted"
            v-if="this.$route.query.category == 'Insurance Claim'"
            >{{ "Insurance Agency Address" }}
          </b>

          <b
            class="text-muted"
            v-else-if="!selectedType.endsWith(' - Organization')"
            >{{ "Recipient Address" }}
          </b>
          <b class="text-muted" v-else>{{ "Organization Address" }} </b>
        </div>

        <div class="col align-self-center">
          <b-form-input
            class="input-address mt-0"
            v-model="receiver_address_1"
            placeholder="Address Line 1"
            autocorrect="off"
            autocomplete="off"
            v-validate="'required'"
            type="text"
            name="Recipient Address 1"
          ></b-form-input>

          <b-form-input
            class="input-address"
            v-model="receiver_address_2"
            placeholder="Address Line 2"
            autocorrect="off"
            autocomplete="off"
            type="text"
          ></b-form-input>

          <b-form-input
            class="input-address"
            v-model="receiver_address_3"
            placeholder="Address Line 3"
            autocorrect="off"
            autocomplete="off"
            type="text"
          ></b-form-input>
          <span class="text-danger small">
            {{ errors.first("Recipient Address 1") }}
          </span>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-sm-12 col-md-3 align-self-center">
        <b>{{ "Incident date" }} </b>
        <span
          id="tooltip-target-6"
          class="text-danger font-weight-bold cursor-pointer mx-1"
        >*</span>
        <b-tooltip
          target="tooltip-target-6"
          triggers="hover"
          placement="top"
          variant="secondary"
          id="tooltip1"
          title="Required"
        >
        </b-tooltip>
      </div>

      <div class="col align-self-center">
        <b-form-datepicker
          id="example-datepicker"
          v-model="letter_date"
          v-validate="'required'"
          type="date"
          name="Incident date"
          :max="maxDate"
        ></b-form-datepicker>
        <span class="text-danger small">
          {{ errors.first("Incident date") }}
        </span>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col">
        <button
          @click="nextStep()"
          class="btn-primary btn px-2 float-right px-4"
        >
          {{ "Next" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  data() {
    return {
      countries: [
        "United States of America",
        "United Kingdom",
        "Australia",
        "Canada",
        "New Zealand",
        "Singapore",
        "Malaysia",
      ],
      country: "",
      sender_types: ["Mr", "Ms", "Mrs"],
      sender_type: "",
      receiver_type: "",
      receiver_types: ["Mr", "Ms", "Mrs"],
      lawyer_name: "",
      sender_name: "",
      sender_address_1: "",
      sender_address_2: "",
      sender_address_3: "",
      receiver_name: "",
      receiver_address_1: "",
      receiver_address_2: "",
      receiver_address_3: "",
      insurance_agency: "",
      title: "",
      letter_date: null, // Use a Date object to store the date
      selectedType: "Individual - Individual",
      InsuranceType: "Auto Insurance Claims",
      law_firm_name: "",
      law_firm_phone_number: "",
      law_firm_email: "",
      law_firm_address: "",
      law_firm_address_2: "",
      law_firm_address_3: "",
      //123, Jalan Harmoni,\nTaman Bahagia,43200 Kajang,\nSelangor,Malaysia
    };
  },
  computed: {
    maxDate() {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0); // Set time to the beginning of the day
      return currentDate;
    },
  },
  methods: {
    changeLetterType() {
      this.sender_type = "";
      this.receiver_type = "";
    },
    nextStep() {
      // Your logic to proceed to the next step goes here

      var tempJuri = "";
      var newJuri = "";
      if (this.country === "United States of America") {
        tempJuri = "USA";
        newJuri = "United States";
      } else {
        tempJuri = this.country;
        newJuri = this.country;
      }

      this.$validator.validateAll().then((result) => {
        if (result) {
          var letterType = "";
          if (
            this.$route.query.category == "Outstanding Payment" ||
            this.$route.query.category == "Property Damage Claim"
          ) {
            letterType = this.selectedType;
          } else {
            letterType = this.InsuranceType;
          }
          var obj = {};
          if (localStorage.lod_first_data) {
            var setValue = JSON.parse(localStorage.lod_first_data);

            obj = {
              info: {
                jurisdiction: newJuri,
                law_firm_name: this.law_firm_name,
                law_firm_phone_number: this.law_firm_phone_number,
                law_firm_email: this.law_firm_email,
                law_firm_address:
                  this.law_firm_address +
                  "\n" +
                  this.law_firm_address_2 +
                  "\n" +
                  this.law_firm_address_3,
                lawyer: this.lawyer_name,
                sender_name: this.sender_name,
                sender_title: this.sender_type,
                sender_address:
                  this.sender_address_1 +
                  "\n" +
                  this.sender_address_2 +
                  "\n" +
                  this.sender_address_3,
                receiver_name: this.receiver_name,
                receiver_title: this.receiver_type,
                receiver_address:
                  this.receiver_address_1 +
                  "\n" +
                  this.receiver_address_2 +
                  "\n" +
                  this.receiver_address_3,
                date: this.letter_date,
                today: new Intl.DateTimeFormat(
                  Intl.DateTimeFormat().resolvedOptions().locale
                ).format(new Date()),
                insurance_agency: this.insurance_agency,
                out_amount: setValue.info.out_amount,
                cost: setValue.info.cost,
                agreement: setValue.info.agreement,
                contract_details: setValue.info.contract_details,
                deadline: setValue.info.deadline,
                additional_info: setValue.info.additional_info,
                outstanding_amount: setValue.info.outstanding_amount,
                currency_type: setValue.info.currency_type,

                claim_number: setValue.info.claim_number,
                policy_number: setValue.info.policy_number,
                documents: setValue.info.documents,
                claim_amount: setValue.info.claim_amount,
                policy_info: setValue.info.policy_info,
                coverage_exp: setValue.info.coverage_exp,

                cost: setValue.info.cost,
                damage_cause: setValue.info.damage_cause,
              },
              compose_type: this.$route.query.category,
              letter_type: letterType,
              location_id: 1,
              // location_id: JSON.parse(localStorage.locations).find(
              //   (x) => x.title === tempJuri
              // ).id,
              language: "en",
            };
          } else {
            obj = {
              info: {
                jurisdiction: newJuri,
                law_firm_name: this.law_firm_name,
                law_firm_phone_number: this.law_firm_phone_number,
                law_firm_email: this.law_firm_email,
                law_firm_address:
                  this.law_firm_address +
                  "\n" +
                  this.law_firm_address_2 +
                  "\n" +
                  this.law_firm_address_3,
                lawyer: this.lawyer_name,
                sender_name: this.sender_name,
                sender_title: this.sender_type,
                sender_address:
                  this.sender_address_1 +
                  "\n" +
                  this.sender_address_2 +
                  "\n" +
                  this.sender_address_3,
                receiver_name: this.receiver_name,
                receiver_title: this.receiver_type,
                receiver_address:
                  this.receiver_address_1 +
                  "\n" +
                  this.receiver_address_2 +
                  "\n" +
                  this.receiver_address_3,
                date: this.letter_date,
                today: new Intl.DateTimeFormat(
                  Intl.DateTimeFormat().resolvedOptions().locale
                ).format(new Date()),

                out_amount: "",
                cost: "",
                agreement: "",
                contract_details: "",
                deadline: "",
                additional_info: "",
                outstanding_amount: "",
                currency_type: "",

                insurance_agency: this.insurance_agency,

                claim_number: "",
                policy_number: "",
                documents: "",
                claim_amount: "",
                policy_info: "",
                coverage_exp: "",

                cost: "",
                damage_cause: "",
              },
              compose_type: this.$route.query.category,
              letter_type: letterType,
              location_id: 1,
              // location_id: JSON.parse(localStorage.locations).find(
              //   (x) => x.title === tempJuri
              // ).id,
              language: "en",
            };
          }

          localStorage.setItem("lod_first_data", JSON.stringify(obj));

          this.$router.push({
            name: "LegalWriterInputs",
            params: {
              step: "letter_details",
            },
            query: {
              category: this.$route.query.category,
            },
          });
        } else {
          this.$toast.error("Please fill in all the information");
        }
      });
    },
  },

  created() {
    this.lawyer_name = JSON.parse(localStorage.userInfo).name;
    this.law_firm_email = JSON.parse(localStorage.userInfo).email;

    if (
      JSON.parse(localStorage.userInfo).extra_info != null &&
      JSON.parse(localStorage.userInfo).extra_info.length != 0
    ) {
      this.law_firm_name = JSON.parse(
        localStorage.userInfo
      ).extra_info.company_name;
      this.law_firm_phone_number = JSON.parse(
        localStorage.userInfo
      ).extra_info.contact_number;
      this.law_firm_address = JSON.parse(
        localStorage.userInfo
      ).extra_info.address;

      const addressParts = JSON.parse(
        localStorage.userInfo
      ).extra_info.address.split("\n");
      this.law_firm_address = addressParts[0];
      this.law_firm_address_2 = addressParts[1];
      this.law_firm_address_3 = addressParts[2];
    }

    // console.log(localStorage.lod_first_data);
    if (localStorage.lod_first_data) {
      var setValue = JSON.parse(localStorage.lod_first_data);
      // console.log(setValue);
      this.country = setValue.info.jurisdiction;
      this.law_firm_name = setValue.info.law_firm_name;
      this.law_firm_phone_number = setValue.info.law_firm_phone_number;
      this.law_firm_email = setValue.info.law_firm_email;
      this.lawyer_name = setValue.info.lawyer;

      this.sender_name = setValue.info.sender_name;
      this.sender_type = setValue.info.sender_title;
      this.receiver_name = setValue.info.receiver_name;
      this.receiver_type = setValue.info.receiver_title;
      this.letter_date = setValue.info.date;

      this.insurance_agency = setValue.info.insurance_agency;

      if (
        this.$route.query.category == "Outstanding Payment" ||
        this.$route.query.category == "Property Damage Claim"
      ) {
        this.selectedType = setValue.letter_type;
      } else {
        this.InsuranceType = setValue.letter_type;
      }

      this.sender_address_1 = setValue.info.sender_address.split("\n")[0];
      this.sender_address_2 = setValue.info.sender_address.split("\n")[1];
      this.sender_address_3 = setValue.info.sender_address.split("\n")[2];

      this.receiver_address_1 = setValue.info.receiver_address.split("\n")[0];
      this.receiver_address_2 = setValue.info.receiver_address.split("\n")[1];
      this.receiver_address_3 = setValue.info.receiver_address.split("\n")[2];

      this.law_firm_address = setValue.info.law_firm_address.split("\n")[0];
      this.law_firm_address_2 = setValue.info.law_firm_address.split("\n")[1];
      this.law_firm_address_3 = setValue.info.law_firm_address.split("\n")[2];
    }
  },
};
</script>

<style scoped>
@media screen and (max-width: 767.98px) {
  .general_container {
    margin: 1rem 2rem 2rem 2rem !important;
  }
}
.general_container {
  margin: 1rem 5rem 2rem 5rem;
}

.row {
  margin: 1rem 0rem;
}

.input-address {
  margin: 1rem 0rem;
}

.help-icon {
  color: #9ac2ef;
  font-size: 1rem;
}

.custom-control-label {
  display: inline-block;
  margin-left: 10px !important;
}

@media only screen and (max-width: 425px) {
  .general_container {
    margin: 0rem 0rem;
  }

  #senderDetailsName {
    margin-bottom: 10px;
  }

  #receiverDetailsName {
    margin-bottom: 10px;
  }
}
</style>
