<template>
  <div>
    <div class="page-wrapper-xl">
      <div class="nexcard p-4">
        <!-- start of Form header -->
        <h3
          v-if="!this.$route.query.sub_category && this.LOD_cateogry.includes(this.$route.query.category)"
          class="modal-card__title text-center mb-4"
        >
          {{ "Create Letter Of Demand" }} - {{ this.$route.query.category }}
        </h3>

        <h3
          v-else-if="!this.$route.query.sub_category"
          class="modal-card__title text-center mb-4"
        >
          {{ "Create" }} {{ this.$route.query.category }}
        </h3>

        <h3 v-else class="modal-card__title text-center mb-4">
          {{ "Create" }} {{ this.$route.query.category }} -
          {{ this.$route.query.sub_category }}
        </h3>
        <!-- end of Form header -->

        <!-- start of Steps Indicator -->
        <div
          style="max-width: 400px"
          class="step__row"
          v-if="!this.$route.query.sub_category"
        >
          <div class="step__col-auto" id="generalDetailsIcon">
            <img
              src="@/assets/input-step-icons/icon-step1-active.svg"
              v-if="this.$route.params.step == 'general_details'"
            />
            <img
              src="@/assets/input-step-icons/icon-step-complete.svg"
              v-else
            />
            <span class="step__name">{{ "General Details" }}</span>
          </div>

          <hr />

          <div class="step__col-auto" id="letterDetailsIcon">
            <img
              src="@/assets/input-step-icons/icon-step2-inactive.svg"
              v-if="this.$route.params.step == 'general_details'"
            />
            <img
              src="@/assets/input-step-icons/icon-step2-active.svg"
              v-else-if="this.$route.params.step == 'letter_details'"
            />
            <img
              src="@/assets/input-step-icons/icon-step-complete.svg"
              v-else
            />
            <span class="step__name">{{ "Letter Details" }}</span>
          </div>
        </div>
        <!-- end of Steps Indicator -->

        <general v-if="this.$route.params.step == 'general_details'" />
        <agreement-general
          v-else-if="this.$route.params.step == 'agreement_general_details'"
        />
        <letter v-else-if="this.$route.params.step == 'letter_details'" />
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import general from "./GeneralDetail.vue";
import AgreementGeneral from "./AgreementGeneralDetail.vue";
import letter from "./LetterDetail.vue";

export default {
  components: {
    general,
    letter,
    AgreementGeneral,
  },
  data() {
    return {
      LOD_cateogry: [
        'Outstanding Payment',
        'Property Damage Claim',
        'Insurance Claim',
      ],
    };
  },
  methods: {},
};
</script>

<style lang="css" scoped>
.content-image {
  position: relative;
  max-width: 1600px;
  margin: 0rem auto;
  padding: 2rem 4rem;
}
.nexcard {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
  /* padding: 1.5rem; */
}

hr {
  width: 60px;
  margin: 1rem 0.5rem 1rem 0.5rem;
}
.step__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 900px;
  margin: 0 auto;
}
.step__col {
  min-width: 20px;
  text-align: start;
  user-select: none;
}

.step__name {
  white-space: pre;
  margin-left: 0.5rem;
}

.btn-cancel {
  width: 80px;
}
.btn-next {
  width: 80px;
  margin-left: 1rem;
}
.route__link {
  text-decoration: none;
  color: #6c757d;
}
.route__link:hover {
  text-decoration: none;
  color: #4f555b;
  text-decoration: underline;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 1250px) {
  hr {
    width: 60px;
    margin: 1rem 0.5rem 1rem 0.5rem;
  }
}

@media only screen and (max-width: 425px) {
  hr {
    width: 20px;
    margin: 1rem 0.5rem 1rem 0.5rem;
  }
}

@media only screen and (max-width: 375px) {
  hr {
    width: 30px;
    margin: 1rem 0.5rem 1rem 0.5rem;
    /* visibility: hidden; */
  }

  #generalDetailsIcon {
    text-align: center;
  }

  #letterDetailsIcon {
    text-align: center;
  }

  img {
    display: block;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 320px) {
  hr {
    width: 30px;
    margin: 1rem 0.5rem 1rem 0.5rem;
    /* visibility: hidden; */
  }

  #generalDetailsIcon {
    text-align: center;
  }

  #letterDetailsIcon {
    text-align: center;
  }

  img {
    display: block;
    margin: 0 auto;
  }

  .step__row {
    display: flex;
    justify-content: center !important;
    align-items: center;
    max-width: 900px;
    margin: 0 auto;
  }
}

/* @media only screen and (max-width: 1200px) {
  .step__row {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  hr {
    visibility: hidden;
    margin: 0.5rem;
  }
} */
</style>
