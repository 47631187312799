<!--
 * How to configure new form for new category/sub category?
 * 
 * [1] Go to: 'Form Construction Syntax'
 *    - Understand the basic of constructing a form
 * 
 * [2] Go to: 'form_fields'
 *    - Start constructing the new form
 *      by adding new category and/or new sub catgory
 * 
 * [3] Go to: <template>
 *    - Update used fields if necessary
 * 
 * [4] Go to: 'CUSTOM FIELDS'
 *    - Construct custom fields in the marked area
 *     Go to: 'addtional fields'
 *    - Define necessary variables
 * 
 * [5] Go to: 'nextStep() {'
 *    - Update corresponding parts
-->
<template>
  <div class="general_container">
    <div
      v-for="(field, index) in form_fields
        ?.[this.$route.query.category]
        ?.[this.$route.query.sub_category]"
      :key="index"
    >
      <!-- Type -->
      <div class="row" v-if="Array.isArray(field) && index == 0">
        <div class="col-12 col-md-3 align-self-center">
          <b>Type</b>
        </div>

        <div class="col align-self-center">
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-radio
              v-for="(type, index) in field"
              :key="index"
              class="my-1"
              v-model="selectedType"
              :aria-describedby="ariaDescribedby"
              @change="changeLetterType()"
              name="some-radios"
              :value="typeValue(type[0] === 'p' ? type[1] + type[1] : type)"
            >
              {{ typeValue(type) }}
            </b-form-radio>
          </b-form-group>
        </div>
      </div>

      <!-- Jurisdiction Selection &
           Lawyer Name &
           Lawfirm Details &
           Parties &
           Agreement Date -->
      <template v-else-if="field === '...'">
        <!-- start of Jurisdiction -->
        <div class="row">
          <div class="col-12 col-md-3 align-self-center">
            <b>
              Jurisdiction
              <span
                id="tooltip-target-1"
                class="text-danger cursor-pointer"
              >*</span>
            </b>
            <b-tooltip
              target="tooltip-target-1"
              triggers="hover"
              placement="top"
              variant="secondary"
              id="tooltip1"
              title="Required"
            ></b-tooltip>
          </div>

          <div class="col align-self-center">
            <b-form-select
              id="inputGroupSelect04"
              :class="[
                'form-select w-100 h-100 rounded',
                { 'text-muted': country === '' },
              ]"
              aria-label="Example select with button addon"
              v-model="country"
              v-validate="'required'"
              name="Country"
            >
              <b-form-select-option value="" disabled selected>
                Please Select a Country
              </b-form-select-option>
              <b-form-select-option
                class="text-softblack"
                :value="item"
                :key="index"
                v-for="(item, index) in countries"
              >
                {{ item }}
              </b-form-select-option>
            </b-form-select>
            <span class="text-danger text-sm" v-show="errors.has('Country')">
              {{ errors.first("Country") }}
            </span>
          </div>
        </div>
        <!-- end of Jurisdiction -->

        <!-- start of Lawyer Name -->
        <div class="row mb-3">
          <div class="col-12 col-md-3 align-self-center">
            <b>
              Lawyer
              <span
                id="tooltip-target-2"
                class="text-danger cursor-pointer"
              >*</span>
            </b>
            <b-tooltip
              target="tooltip-target-2"
              triggers="hover"
              placement="top"
              variant="secondary"
              id="tooltip1"
              title="Required"
            ></b-tooltip>
          </div>

          <div class="col align-self-center">
            <b-form-input
              v-model="lawyer_name"
              placeholder="First Name"
              autocorrect="off"
              autocomplete="off"
              v-validate="'required'"
              type="text"
              name="Lawyer Name"
            ></b-form-input>
            <span class="text-danger small">
              {{ errors.first("Lawyer Name") }}
            </span>
          </div>
        </div>
        <!-- end of Lawyer Name -->

        <!-- start of Lawfirm Details -->
        <b>
          Law Firm Details
          <span
            id="tooltip-target-3"
            class="text-danger cursor-pointer"
          >*</span>
        </b>
        <b-tooltip
          target="tooltip-target-3"
          triggers="hover"
          placement="top"
          variant="secondary"
          id="tooltip1"
          title="Required"
        ></b-tooltip>

        <div class="card border shadow rounded py-2 px-4 mt-1 mb-3">
          <div class="row">
            <div class="col-12 col-md-3 align-self-center">
              <b class="text-muted">Name</b>
            </div>

            <div class="col align-self-center">
              <b-form-input
                v-model="law_firm_name"
                placeholder=""
                autocorrect="off"
                autocomplete="off"
                v-validate="'required'"
                type="text"
                name="Law Firm Name"
              ></b-form-input>
              <span class="text-danger small">
                {{ errors.first("Law Firm Name") }}
              </span>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-3 align-self-center">
              <b class="text-muted">{{ "Phone Number" }}</b>
            </div>

            <div class="col align-self-center">
              <b-form-input
                v-model="law_firm_phone_number"
                placeholder=""
                autocorrect="off"
                autocomplete="off"
                v-validate="'required'"
                type="text"
                name="Law Firm Contact Number"
              ></b-form-input>
              <span class="text-danger small">
                {{ errors.first("Law Firm Contact Number") }}
              </span>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-3 align-self-center">
              <b class="text-muted">{{ "Email" }}</b>
            </div>

            <div class="col align-self-center">
              <b-form-input
                v-model="law_firm_email"
                placeholder=""
                autocorrect="off"
                autocomplete="off"
                v-validate="'required'"
                type="email"
                name="Email"
              ></b-form-input>
              <span class="text-danger small" v-show="errors.has('Email')">
                {{ errors.first("Email") }}
              </span>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-3 align-self-center">
              <b class="text-muted">{{ "Address" }}</b>
            </div>

            <div class="col align-self-center">
              <b-form-input
                v-model="law_firm_address[0]"
                placeholder="Address Line 1"
                autocorrect="off"
                autocomplete="off"
                v-validate="'required'"
                type="text"
                name="Address Line 1"
                class="input-address"
              ></b-form-input>
              <b-form-input
                v-model="law_firm_address[1]"
                placeholder="Address Line 2"
                autocorrect="off"
                autocomplete="off"
                class="input-address"
              ></b-form-input>
              <b-form-input
                v-model="law_firm_address[2]"
                placeholder="Address Line 3"
                autocorrect="off"
                autocomplete="off"
                class="input-address"
              ></b-form-input>
              <span class="text-danger small">
                {{ errors.first("Address Line 1") }}
              </span>
            </div>
          </div>
        </div>
        <!-- end of Lawfirm Details -->
      </template>

      <template v-else-if="Array.isArray(field) && index > 0">
        <!-- start of Party A -->
        <b>
          {{ field[0] }} Details
          <span
            id="tooltip-target-4"
            class="text-danger cursor-pointer"
          >*</span>
        </b>
        <b-tooltip
          target="tooltip-target-4"
          triggers="hover"
          placement="top"
          variant="secondary"
          id="tooltip1"
          title="Required"
        ></b-tooltip>

        <div class="card border shadow rounded py-2 px-4 mt-1 mb-3">
          <div class="row">
            <div class="col-12 col-md-3 align-self-center">
              <b class="text-muted">{{ "Name" }} </b>
            </div>
            <div
              class="col-3 col-xl-2"
              v-if="
                selectedType.split(' - ')[0] == 'Individual' ||
                selectedType.split(' - ')[0] == 'Producer'
              "
            >
              <select
                class="form-select w-100"
                id="inputGroupSelect04"
                aria-label="Example select with button addon"
                v-model="sender_type"
              >
                <option
                  :value="sender"
                  :key="index"
                  v-for="(sender, index) in sender_types"
                >
                  {{ sender }}
                </option>
              </select>
            </div>
            <div class="col align-self-center">
              <b-form-input
                v-model="sender_name"
                placeholder="Full Name"
                autocorrect="off"
                autocomplete="off"
                v-validate="'required'"
                type="text"
                name="Full Name"
              ></b-form-input>
              <span
                class="text-danger text-sm"
                v-show="errors.has('Full Name')"
              >
                {{ errors.first("Full Name") }}
              </span>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-3 align-self-center">
              <b class="text-muted">{{ "Address" }} </b>
            </div>

            <div class="col align-self-center">
              <b-form-input
                class="input-address"
                v-model="sender_address[0]"
                placeholder="Address Line 1"
                autocorrect="off"
                autocomplete="off"
                v-validate="'required'"
                type="text"
                name="Sender Address 1"
              ></b-form-input>

              <b-form-input
                class="input-address"
                v-model="sender_address[1]"
                placeholder="Address Line 2"
                autocorrect="off"
                autocomplete="off"
              ></b-form-input>

              <b-form-input
                class="input-address"
                v-model="sender_address[2]"
                placeholder="Address Line 3"
                autocorrect="off"
                autocomplete="off"
              ></b-form-input>
              <span
                class="text-danger text-sm"
                v-show="errors.has('Sender Address 1')"
              >
                {{ errors.first("Sender Address 1") }}
              </span>
            </div>
          </div>
        </div>
        <!-- end of Party A -->

        <!-- start of Party B -->
        <template v-if="field.length === 2">
          <b>
            {{ field[1] }} Details
            <span
              id="tooltip-target-5"
              class="text-danger cursor-pointer"
            >*</span>
          </b>
          <b-tooltip
            target="tooltip-target-5"
            triggers="hover"
            placement="top"
            variant="secondary"
            id="tooltip1"
            title="Required"
          ></b-tooltip>

          <div class="card border shadow rounded py-2 px-4 mt-1 mb-3">
            <div class="row">
              <div class="col-12 col-md-3 align-self-center">
                <b class="text-muted">{{ "Name" }}</b>
              </div>
              <div
                class="col-12 col-sm-3 col-xl-2"
                v-if="
                  selectedType !== typeValue('io') &&
                  selectedType !== typeValue('oo')
                "
              >
                <select
                  class="form-select w-100 rounded"
                  id="inputGroupSelect04"
                  aria-label="Example select with button addon"
                  v-model="receiver_type"
                  v-validate="'required'"
                  name="Receiver Title"
                >
                  <option
                    :value="receiver"
                    :key="index"
                    v-for="(receiver, index) in receiver_types"
                  >
                    {{ receiver }}
                  </option>
                </select>
              </div>

              <div class="col align-self-center">
                <b-form-input
                  v-model="receiver_name"
                  placeholder="Full Name"
                  autocorrect="off"
                  autocomplete="off"
                  v-validate="'required'"
                  type="text"
                  name="Receiver Name"
                ></b-form-input>
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('Receiver Name')"
                >
                  {{ errors.first("Receiver Name") }}
                </span>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-3 align-self-center">
                <b class="text-muted">{{ "Address" }}</b>
              </div>

              <div class="col align-self-center">
                <b-form-input
                  class="input-address"
                  v-model="receiver_address[0]"
                  placeholder="Address Line 1"
                  autocorrect="off"
                  autocomplete="off"
                  v-validate="'required'"
                  type="text"
                  name="Receiver Address 1"
                ></b-form-input>
                <b-form-input
                  class="input-address"
                  v-model="receiver_address[1]"
                  placeholder="Address Line 2"
                  autocorrect="off"
                  autocomplete="off"
                  type="text"
                  name="Receiver Address 2"
                ></b-form-input>
                <b-form-input
                  class="input-address"
                  v-model="receiver_address[2]"
                  placeholder="Address Line 3"
                  autocorrect="off"
                  autocomplete="off"
                ></b-form-input>
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('Receiver Address 1')"
                >
                  {{ errors.first("Receiver Address 1") }}
                </span>
              </div>
            </div>
          </div>
        </template>
        <!-- end of Party B -->
      </template>

      <div v-else-if="field === '..'" class="row">
        <div class="col-12 col-md-3 align-self-center">
          <b>
            {{ "Agreement Date" }}
            <span
              id="tooltip-target-6"
              class="text-danger cursor-pointer"
            >*</span>
          </b>
          <b-tooltip
            target="tooltip-target-6"
            triggers="hover"
            placement="top"
            variant="secondary"
            id="tooltip1"
            title="Required"
          ></b-tooltip>
        </div>

        <div class="col align-self-center">
          <b-form-datepicker
            id="example-datepicker"
            v-model="letter_date"
            v-validate="'required'"
            type="date"
            name="Incident date"
            :max="maxDate"
          ></b-form-datepicker>
          <span
            class="text-danger text-sm"
            v-show="errors.has('Incident date')"
          >
            {{ errors.first("Incident date") }}
          </span>
        </div>
      </div>

      <!-- ## start of CUSTOM FIELDS ##-->

      <!-- Partnership -->
      <div v-else-if="field === 'Business Information'" class="row">
        <div class="col-12 col-md-3 align-self-center">
          <b>
            {{ field }}
            <span
              id="tooltip-target-7"
              class="text-danger cursor-pointer"
            >*</span>
          </b>
          <b-tooltip
            target="tooltip-target-7"
            triggers="hover"
            placement="top"
            variant="secondary"
            id="tooltip1"
            title="Required"
          ></b-tooltip>
        </div>

        <div class="col align-self-center">
          <b-form-input
            v-model="business_info"
            placeholder="Information about the business"
            autocorrect="off"
            autocomplete="off"
            v-validate="'required'"
            type="text"
            name="Business Information"
          ></b-form-input>
          <span
            class="text-danger text-sm"
            v-show="errors.has('Business Information')"
          >
            {{ errors.first("Business Information") }}
          </span>
        </div>
      </div>
      <!-- Partnership / Creative Partnerships -->
      <div v-else-if="field === 'Creative Field'" class="row">
        <div class="col-12 col-md-3 align-self-center">
          <b>
            Creative Field
            <span
              id="tooltip-target-8"
              class="text-danger cursor-pointer"
            >*</span>
          </b>
          <b-tooltip
            target="tooltip-target-8"
            triggers="hover"
            placement="top"
            variant="secondary"
            id="tooltip1"
            title="Required"
          ></b-tooltip>
        </div>

        <div class="col align-self-center">
          <b-form-input
            v-model="creative"
            placeholder="Example: Music collaboration, Podcast, Writers, Movie Making"
            autocorrect="off"
            autocomplete="off"
            v-validate="'required'"
            type="text"
            name="Creative"
          ></b-form-input>
          <span class="text-danger text-sm" v-show="errors.has('Creative')">
            {{ errors.first("Creative") }}
          </span>
        </div>
      </div>
      <!-- Partnership / Technology Ventures -->
      <div v-else-if="field === 'Technology Field'" class="row">
        <div class="col-12 col-md-3 align-self-center">
          <b>
            Technology Field
            <span
              id="tooltip-target-9"
              class="text-danger cursor-pointer"
            >*</span>
          </b>
          <b-tooltip
            target="tooltip-target-9"
            triggers="hover"
            placement="top"
            variant="secondary"
            id="tooltip1"
            title="Required"
          ></b-tooltip>
        </div>

        <div class="col align-self-center">
          <b-form-input
            v-model="technology"
            placeholder="Example: Web development"
            autocorrect="off"
            autocomplete="off"
            v-validate="'required'"
            type="text"
            name="Technology"
          ></b-form-input>
          <span class="text-danger text-sm" v-show="errors.has('Technologgy')">
            {{ errors.first("Technology") }}
          </span>
        </div>
      </div>

      <!-- Sales and Purchase / International -->
      <div v-else-if="field === 'International'" class="row">
        <div class="col-12 col-md-3 align-self-center">
          <b>
            {{ "From - To" }}
            <span
              id="tooltip-target-10"
              class="text-danger cursor-pointer"
            >*</span>
          </b>
          <b-tooltip
            target="tooltip-target-10"
            triggers="hover"
            placement="top"
            variant="secondary"
            id="tooltip1"
            title="Required"
          ></b-tooltip>
        </div>

        <div class="col align-self-center">
          <select
            class="form-select w-100 h-100"
            id="inputGroupSelect04"
            aria-label="Example select with button addon"
            v-model="international_category"
            v-validate="'required'"
            name="From"
          >
            <option
              v-show="international_category_to != cat"
              :value="cat"
              :key="index"
              v-for="(cat, index) in from_international_category"
            >
              {{ cat }}
            </option>
          </select>
        </div>

        <div class="col align-self-center">
          <select
            class="form-select w-100 h-100"
            id="inputGroupSelect04"
            aria-label="Example select with button addon"
            v-model="international_category_to"
            v-validate="'required'"
            name="To"
          >
            <option
              v-show="international_category != cat"
              :value="cat"
              :key="index"
              v-for="(cat, index) in to_international_category"
            >
              {{ cat }}
            </option>
          </select>
        </div>
      </div>

      <!-- Non-Disclosure Agreement / Partnership -->
      <div v-else-if="field === 'Partnership'" class="row">
        <div class="col-12 col-md-3 align-self-center">
          <b>
            Partnership Description
            <span
              id="tooltip-target-11"
              class="text-danger cursor-pointer"
            >*</span>
          </b>
          <b-tooltip
            target="tooltip-target-11"
            triggers="hover"
            placement="top"
            variant="secondary"
            id="tooltip1"
            title="Required"
          ></b-tooltip>
        </div>

        <div class="col align-self-center">
          <b-form-input
            v-model="partnership_description"
            placeholder="Describe the nature and type of the partnership"
            autocorrect="off"
            autocomplete="off"
            v-validate="'required'"
            type="text"
            name="Partnership Description"
          ></b-form-input>
          <span
            class="text-danger text-sm"
            v-show="errors.has('Partnership Description')"
          >
            {{ errors.first("Partnership Description") }}
          </span>
        </div>
      </div>
      <!-- Non-Disclosure Agreement / Board Members -->
      <div v-else-if="field === 'Board Description'" class="row">
        <div class="col-12 col-md-3 align-self-center">
          <b>
            Board Description
            <span
              id="tooltip-target-12"
              class="text-danger cursor-pointer"
            >*</span>
          </b>
          <b-tooltip
            target="tooltip-target-12"
            triggers="hover"
            placement="top"
            variant="secondary"
            id="tooltip1"
            title="Required"
          ></b-tooltip>
        </div>

        <div class="col align-self-center">
          <b-form-input
            v-model="board_description"
            placeholder="Describe the type and purpose of the board"
            autocorrect="off"
            autocomplete="off"
            v-validate="'required'"
            type="text"
            name="Board Description"
          ></b-form-input>
          <span class="text-danger text-sm" v-show="errors.has(field)">
            {{ errors.first(field) }}
          </span>
        </div>
      </div>

      <!-- Creative Media -->
      <div
        class="row"
        v-else-if="
          $route.query.category == 'Creative Media' && field === 'Product Type'
        "
      >
        <div class="col-12 col-md-3 align-self-center">
          <b>
            Product Type
            <span
              id="tooltip-target-13"
              class="text-danger cursor-pointer"
            >*</span>
          </b>
          <b-tooltip
            target="tooltip-target-13"
            triggers="hover"
            placement="top"
            variant="secondary"
            id="tooltip1"
            title="Required"
          ></b-tooltip>
        </div>

        <div class="col align-self-center">
          <b-form-select
            class="form-select w-100 h-100 rounded"
            :class="{ 'text-muted': product_type === '' }"
            id="inputGroupSelect04"
            aria-label="Example select with button addon"
            v-model="product_type"
            v-validate="'required'"
            name="Product Type"
          >
            <b-form-select-option value="" disabled selected>
              {{ "Please Select a Product Type" }}
            </b-form-select-option>
            <b-form-select-option
              class="text-softblack"
              :value="item"
              :key="index"
              v-for="(item, index) in products"
            >
              {{ item }}
            </b-form-select-option>
          </b-form-select>
          <span class="text-danger text-sm" v-show="errors.has(field)">
            {{ errors.first(field) }}
          </span>
        </div>
      </div>
      <div v-else-if="['Project Description'].includes(field)" class="row">
        <div class="col-12 col-md-3 align-self-center">
          <b>
            {{ field }}
            <span
              id="tooltip-target-31"
              class="text-danger cursor-pointer"
            >*</span>
          </b>
          <b-tooltip
            target="tooltip-target-31"
            triggers="hover"
            placement="top"
            variant="secondary"
            id="tooltip1"
            title="Required"
          ></b-tooltip>
        </div>

        <div class="col align-self-center">
          <b-form-textarea
            v-model="project_description"
            :placeholder="
              form_fields?.[$route.query.category]?.extra
                ?.productDesc_placeholder?.[$route.query.sub_category] ?? field
            "
            rows="3"
            autocorrect="off"
            autocomplete="off"
            v-validate="'required'"
            type="text"
            :name="field"
          ></b-form-textarea>
          <span class="text-danger text-sm" v-show="errors.has(field)">
            {{ errors.first(field) }}
          </span>
        </div>
      </div>
      <div
        v-else-if="
          ['Product Description', 'Description of Product'].includes(field)
        "
        class="row"
      >
        <div class="col-12 col-md-3 align-self-center">
          <b>
            {{ field }}
            <span
              id="tooltip-target-14"
              class="text-danger font-weight-bold cursor-pointer mx-1"
            >*</span>
          </b>
          <b-tooltip
            target="tooltip-target-14"
            triggers="hover"
            placement="top"
            variant="secondary"
            id="tooltip1"
            title="Required"
          ></b-tooltip>
        </div>

        <div class="col align-self-center">
          <b-form-textarea
            v-model="product_description"
            :placeholder="
              form_fields?.[$route.query.category]?.extra
                ?.productDesc_placeholder?.[$route.query.sub_category] ?? field
            "
            rows="3"
            autocorrect="off"
            autocomplete="off"
            v-validate="'required'"
            type="text"
            :name="field"
          ></b-form-textarea>
          <span class="text-danger text-sm" v-show="errors.has(field)">
            {{ errors.first(field) }}
          </span>
        </div>
      </div>
      <!-- Creative Media / Option Agreement -->
      <div v-else-if="field === 'Option Duration'" class="row">
        <div class="col-12 col-md-3 align-self-center">
          <b>
            Option Duration
            <span
              id="tooltip-target-15"
              class="text-danger font-weight-bold cursor-pointer mx-1"
            >*</span>
          </b>
          <b-tooltip
            target="tooltip-target-15"
            triggers="hover"
            placement="top"
            variant="secondary"
            id="tooltip1"
            title="Required"
          ></b-tooltip>
        </div>

        <div class="col align-self-center">
          <b-form-input
            v-model="option_duration"
            placeholder="Specify the option duration (eg: 1 week/ 20th Jan 2023 to 27th Jan 2023/ etc)"
            autocorrect="off"
            autocomplete="off"
            v-validate="'required'"
            type="text"
            name="Option Duration"
          ></b-form-input>
          <span
            class="text-danger text-sm"
            v-show="errors.has('Option Duration')"
          >
            {{ errors.first("Option Duration") }}
          </span>
        </div>
      </div>
      <!-- $$ -->
      <div
        v-else-if="typeof field === 'string' && field.startsWith('$$')"
        class="row"
      >
        <div class="col-12 col-md-3 align-self-center">
          <b>
            {{ field.split("$$").pop() }}
            <span
              id="tooltip-target-16"
              class="text-danger cursor-pointer"
            >*</span>
          </b>
          <b-tooltip
            target="tooltip-target-16"
            triggers="hover"
            placement="top"
            variant="secondary"
            id="tooltip1"
            title="Required"
          ></b-tooltip>
        </div>

        <div class="col-7 col-md-6 col-xl-5 align-self-center">
          <v-select
            ref="mySelect2"
            v-model="chosenCurrencyType2"
            v-validate="'required'"
            :options="currency_type"
            label="name"
            class="new-styles"
          >
            <template #option="option">
              <span :class="'fi fi-' + option.flag"></span>
              <span class="mx-3">{{ option.name }}</span>
            </template>
          </v-select>
        </div>

        <div class="col col-xl-4 align-self-center">
          <b-form-input
            v-model="price2"
            placeholder="Amount"
            autocorrect="off"
            autocomplete="off"
            v-validate="'numeric'"
            type="text"
            :name="field"
          ></b-form-input>
          <span
            class="text-danger text-sm"
            v-show="errors.has(field.split('$$').pop())"
          >
            {{ errors.first(field.split("$$").pop()) }}
          </span>
        </div>
      </div>
      <!-- Creative Media / Film Production Agreement -->
      <div v-else-if="field === 'Movie Name'" class="row">
        <div class="col-12 col-md-3 align-self-center">
          <b>
            Movie Name
            <span
              id="tooltip-target-17"
              class="text-danger cursor-pointer"
            >*</span>
          </b>
          <b-tooltip
            target="tooltip-target-17"
            triggers="hover"
            placement="top"
            variant="secondary"
            id="tooltip1"
            title="Required"
          ></b-tooltip>
        </div>

        <div class="col align-self-center">
          <b-form-input
            v-model="movie_name"
            placeholder="Specify the name of the film"
            autocorrect="off"
            autocomplete="off"
            v-validate="'required'"
            type="text"
            name="Movie Name"
          ></b-form-input>
          <span class="text-danger text-sm" v-show="errors.has('Movie Name')">
            {{ errors.first("Movie Name") }}
          </span>
        </div>
      </div>
      <!-- $$$ -->
      <div
        v-else-if="typeof field === 'string' && field.startsWith('$$$')"
        class="row"
      >
        <div class="col-12 col-md-3 align-self-center">
          <b>
            {{ field.split("$$$").pop() }}
            <span
              id="tooltip-target-18"
              class="text-danger cursor-pointer"
            >*</span>
          </b>
          <b-tooltip
            target="tooltip-target-18"
            triggers="hover"
            placement="top"
            variant="secondary"
            id="tooltip1"
            title="Required"
          ></b-tooltip>
        </div>

        <div class="col-7 col-md-6 col-xl-5 align-self-center">
          <v-select
            ref="mySelect3"
            v-model="chosenCurrencyType3"
            v-validate="'required'"
            :options="currency_type"
            label="name"
            class="new-styles"
          >
            <template #option="option">
              <span :class="'fi fi-' + option.flag"></span>
              <span class="mx-3">{{ option.name }}</span>
            </template>
          </v-select>
        </div>

        <div class="col col-xl-4 align-self-center">
          <b-form-input
            v-model="price3"
            placeholder="Amount"
            autocorrect="off"
            autocomplete="off"
            v-validate="'numeric'"
            type="text"
            :name="field"
          ></b-form-input>
          <span
            class="text-danger text-sm"
            v-show="errors.has(field.split('$$$').pop())"
          >
            {{ errors.first(field.split("$$$").pop()) }}
          </span>
        </div>
      </div>
      <!-- Creative Media / Loan of Art Agreement -->
      <div v-else-if="field === 'Loan Purpose'" class="row">
        <div class="col-12 col-md-3 align-self-center">
          <b>
            Loan Purpose
            <span
              id="tooltip-target-19"
              class="text-danger cursor-pointer"
            >*</span>
          </b>
          <b-tooltip
            target="tooltip-target-19"
            triggers="hover"
            placement="top"
            variant="secondary"
            id="tooltip1"
            title="Required"
          ></b-tooltip>
        </div>

        <div class="col align-self-center">
          <b-form-input
            v-model="loan_purpose"
            placeholder="Specify the purpose of the loan"
            autocorrect="off"
            autocomplete="off"
            v-validate="'required'"
            type="text"
            name="Loan Purpose"
          ></b-form-input>
          <span class="text-danger text-sm" v-show="errors.has('Loan Purpose')">
            {{ errors.first("Loan Purpose") }}
          </span>
        </div>
      </div>

      <!-- Others / IPA Agreement -->
      <div
        class="row"
        v-else-if="
          $route.query.category == 'Others' && field == 'Description of Goods'
        "
      >
        <div class="col-12 col-md-3 align-self-center">
          <b>
            Description of Goods
            <span
              id="tooltip-target-20"
              class="text-danger cursor-pointer"
            >*</span>
          </b>
          <b-tooltip
            target="tooltip-target-20"
            triggers="hover"
            placement="top"
            variant="secondary"
            id="tooltip1"
            title="Required"
          ></b-tooltip>
        </div>

        <div class="col align-self-center">
          <b-form-textarea
            v-model="ipa_description"
            placeholder="Describe the intellectual property being assigned, including patents, trademarks, copyrights, trade secrets, or any other intangible assets"
            autocorrect="off"
            autocomplete="off"
            v-validate="'required'"
            type="text"
            rows="3"
            maxrow="3"
            :name="field"
          ></b-form-textarea>
          <span class="text-danger text-sm" v-show="errors.has(field)">
            {{ errors.first(field) }}
          </span>
        </div>
      </div>
      <!-- Others / Distribution Agreement -->
      <div class="row" v-else-if="field == 'Product Type'">
        <div class="col-12 col-md-3 align-self-center">
          <b>
            {{ field }}
            <span
              id="tooltip-target-21"
              class="text-danger cursor-pointer"
            >*</span>
          </b>
          <b-tooltip
            target="tooltip-target-21"
            triggers="hover"
            placement="top"
            variant="secondary"
            id="tooltip1"
            title="Required"
          >
          </b-tooltip>
        </div>

        <div class="col align-self-center">
          <b-form-select
            class="form-select w-100 h-100 rounded"
            :class="{ 'text-muted': product_type === '' }"
            id="inputGroupSelect04"
            aria-label="Example select with button addon"
            v-model="product_type"
            v-validate="'required'"
            name="Product Type"
          >
            <b-form-select-option value="" disabled selected>
              {{ "Please Select a Product Type" }}
            </b-form-select-option>
            <b-form-select-option
              class="text-softblack"
              :value="item"
              :key="index"
              v-for="(item, index) in general_products"
            >
              {{ item }}
            </b-form-select-option>
          </b-form-select>
          <span class="text-danger text-sm" v-show="errors.has(field)">
            {{ errors.first(field) }}
          </span>
        </div>
      </div>
      <!-- Others / Franchise Agreement -->
      <div class="row" v-else-if="field == 'Business Description'">
        <div class="col-12 col-md-3 align-self-center">
          <b>
            {{ field }}
            <span
              id="tooltip-target-22"
              class="text-danger cursor-pointer"
            >*</span>
          </b>
          <b-tooltip
            target="tooltip-target-22"
            triggers="hover"
            placement="top"
            variant="secondary"
            id="tooltip1"
            title="Required"
          ></b-tooltip>
        </div>

        <div class="col align-self-center">
          <b-form-textarea
            v-model="business_description"
            :placeholder="field"
            autocorrect="off"
            autocomplete="off"
            v-validate="'required'"
            type="text"
            rows="2"
            maxrow="2"
            :name="field"
          ></b-form-textarea>
          <span class="text-danger text-sm" v-show="errors.has(field)">
            {{ errors.first(field) }}
          </span>
        </div>
      </div>
      <!-- Others / Independent Constructor Agreement -->
      <div class="row" v-else-if="field == 'Constructor Description'">
        <div class="col-12 col-md-3 align-self-center">
          <b>
            {{ field }}
            <span
              id="tooltip-target-23"
              class="text-danger cursor-pointer"
            >*</span>
          </b>
          <b-tooltip
            target="tooltip-target-23"
            triggers="hover"
            placement="top"
            variant="secondary"
            id="tooltip1"
            title="Required"
          ></b-tooltip>
        </div>

        <div class="col align-self-center">
          <b-form-textarea
            v-model="constructor_description"
            :placeholder="field"
            autocorrect="off"
            autocomplete="off"
            v-validate="'required'"
            type="text"
            rows="2"
            maxrow="2"
            :name="field"
          ></b-form-textarea>
          <span class="text-danger text-sm" v-show="errors.has(field)">
            {{ errors.first(field) }}
          </span>
        </div>
      </div>

      <!--## end of CUSTOM FIELDS ##-->

      <!-- :quantity -->
      <div v-else-if="field === 'Qty'" class="row">
        <div class="col-12 col-md-3 align-self-center">
          <b>
            Quantity
            <span
              id="tooltip-target-24"
              class="text-danger cursor-pointer"
            >*</span>
          </b>
          <b-tooltip
            target="tooltip-target-24"
            triggers="hover"
            placement="top"
            variant="secondary"
            id="tooltip1"
            title="Required"
          ></b-tooltip>
        </div>

        <div class="col align-self-center">
          <b-form-input
            v-model="quantity"
            placeholder="Quantity"
            autocorrect="off"
            autocomplete="off"
            v-validate="'required'"
            type="number"
            name="Quantity"
          ></b-form-input>
          <span class="text-danger text-sm" v-show="errors.has('Quantity')">
            {{ errors.first("Quantity") }}
          </span>
        </div>
      </div>

      <!-- :item_description -->
      <div
        v-else-if="
          [
            'Description of Property',
            'Description of Stock/Share',
            'Description of Service',
            'Description of Goods',
            'Description of Asset',
            'Description of Business',
            'Job Description',
            'Item Description',
            'Investment Description',
            'Consultancy Description',
            'Artwork Description',
            'Product/Service Description',
            'Web Design Description',
            'Sponsorship Description',
          ].includes(field)
        "
        class="row"
      >
        <div class="col-12 col-md-3 align-self-center">
          <b>
            {{ field }}
            <span
              id="tooltip-target-25"
              class="text-danger cursor-pointer"
            >*</span>
          </b>
          <b-tooltip
            target="tooltip-target-25"
            triggers="hover"
            placement="top"
            variant="secondary"
            id="tooltip1"
            title="Required"
          ></b-tooltip>
        </div>

        <div class="col align-self-center">
          <b-form-textarea
            v-model="item_description"
            :placeholder="
              form_fields?.[$route.query.category]?.extra
                ?.itemDesc_placeholder?.[$route.query.sub_category] ?? field
            "
            rows="2"
            autocorrect="off"
            autocomplete="off"
            v-validate="'required'"
            type="text"
            :name="field"
          ></b-form-textarea>
          <span class="text-danger text-sm" v-show="errors.has(field)">
            {{ errors.first(field) }}
          </span>
        </div>
      </div>

      <!-- :item -->
      <div
        v-else-if="['Service/Package Name', 'Item'].includes(field)"
        class="row"
      >
        <div class="col-12 col-md-3 align-self-center">
          <b>
            {{ field }}
            <span
              id="tooltip-target-26"
              class="text-danger cursor-pointer"
            >*</span>
          </b>
          <b-tooltip
            target="tooltip-target-26"
            triggers="hover"
            placement="top"
            variant="secondary"
            id="tooltip1"
            title="Required"
          ></b-tooltip>
        </div>

        <div class="col align-self-center">
          <b-form-input
            v-model="item"
            :placeholder="field"
            autocorrect="off"
            autocomplete="off"
            v-validate="'required'"
            type="text"
            :name="field"
          ></b-form-input>
          <span class="text-danger text-sm" v-show="errors.has(field)">
            {{ errors.first(field) }}
          </span>
        </div>
      </div>

      <!-- :condition -->
      <div
        v-else-if="
          [
            'Condition of Property',
            'Description of Subscription',
            'Condition',
          ].includes(field)
        "
        class="row"
      >
        <div class="col-12 col-md-3 align-self-center">
          <b>
            {{ field }}
            <span
              id="tooltip-target-27"
              class="text-danger cursor-pointer"
            >*</span>
          </b>
          <b-tooltip
            target="tooltip-target-27"
            triggers="hover"
            placement="top"
            variant="secondary"
            id="tooltip1"
            title="Required"
          ></b-tooltip>
        </div>

        <div class="col align-self-center">
          <b-form-input
            v-model="condition"
            :placeholder="
              form_fields?.[$route.query.category]?.extra
                ?.condition_placeholder?.[$route.query.sub_category] ?? field
            "
            autocorrect="off"
            autocomplete="off"
            v-validate="'required'"
            type="text"
            :name="field"
          ></b-form-input>
          <span class="text-danger text-sm" v-show="errors.has(field)">
            {{ errors.first(field) }}
          </span>
        </div>
      </div>

      <!-- From-To -->
      <div class="row" v-else-if="field === 'From-To' || field === 'From-To!'">
        <div class="col-12 col-md-3 align-self-center">
          <b>
            {{ "From - To" }}
            <span
              v-if="field === 'From-To'"
              id="tooltip-target-28"
              class="text-danger cursor-pointer"
            >*</span>
          </b>
          <b-tooltip
            target="tooltip-target-28"
            triggers="hover"
            placement="top"
            variant="secondary"
            id="tooltip1"
            title="Required"
          ></b-tooltip>
        </div>

        <div class="col align-self-center" v-if="field === 'From-To'">
          <select
            class="form-select w-100 h-100 rounded"
            id="inputGroupSelect04"
            aria-label="Example select with button addon"
            v-model="from"
            v-validate="'required'"
            name="From"
            @change="changeFromTo"
          >
            <option
              :value="cat"
              :key="index"
              v-for="(cat, index) in from_to_category"
            >
              {{ cat }}
            </option>
          </select>
        </div>

        <div class="col align-self-center" v-else>
          <b-form-input
            v-model="from"
            placeholder=""
            autocorrect="off"
            autocomplete="off"
            disabled
          ></b-form-input>
        </div>

        <div class="col align-self-center">
          <b-form-input
            v-model="to"
            placeholder=""
            autocorrect="off"
            autocomplete="off"
            disabled
          ></b-form-input>
        </div>
      </div>

      <!-- $ -->
      <div
        v-else-if="typeof field === 'string' && field.startsWith('$')"
        class="row"
      >
        <div class="col-12 col-md-3 align-self-center">
          <b>
            {{ field.split("$").pop() }}
            <span
              id="tooltip-target-29"
              class="text-danger cursor-pointer"
            >*</span>
          </b>
          <b-tooltip
            target="tooltip-target-29"
            triggers="hover"
            placement="top"
            variant="secondary"
            id="tooltip1"
            title="Required"
          >
          </b-tooltip>
        </div>

        <div class="col-7 col-md-6 col-xl-5 align-self-center">
          <v-select
            ref="mySelect"
            v-model="chosenCurrencyType"
            v-validate="'required'"
            :options="currency_type"
            label="name"
            class="new-styles"
          >
            <template #option="option">
              <span :class="'fi fi-' + option.flag"></span>
              <span class="mx-3">{{ option.name }}</span>
            </template>
          </v-select>
        </div>

        <div class="col col-xl-4 align-self-center">
          <b-form-input
            v-model="price"
            placeholder="Amount"
            autocorrect="off"
            autocomplete="off"
            v-validate="'numeric'"
            type="text"
            :name="field"
          ></b-form-input>
          <span
            class="text-danger text-sm"
            v-show="errors.has(field.split('$').pop())"
          >
            {{ errors.first(field.split("$").pop()) }}
          </span>
        </div>
      </div>

      <!-- Additional Information -->
      <div v-else-if="field === '+'" class="row">
        <div class="col-12 col-md-3 align-self-center">
          <b>
            Additional Information
            <span
              id="tooltip-target-30"
              class="text-danger cursor-pointer"
            >*</span>
          </b>
          <b-tooltip
            target="tooltip-target-30"
            triggers="hover"
            placement="top"
            variant="secondary"
            id="tooltip1"
            title="Required"
          ></b-tooltip>
        </div>

        <div class="col align-self-center">
          <b-form-textarea
            v-model="additional_info"
            :placeholder="
              form_fields?.[$route.query.category]?.extra
                ?.addInfo_placeholder?.[$route.query.sub_category] ?? ''
            "
            rows="5"
            autocorrect="off"
            autocomplete="off"
            v-validate="'required'"
            type="text"
            name="Additional Information"
          ></b-form-textarea>
          <span
            class="text-danger text-sm"
            v-show="errors.has('Additional Information')"
          >
            {{ errors.first("Additional Information") }}
          </span>
        </div>
      </div>

      <!-- DEBUG # Invalid Field # -->
      <!-- <div class="row" v-else>[!!] {{ field }} [!!]</div> -->
    </div>

    <div style="height: 2rem;">
      <button
        @click="nextStep()"
        class="btn-primary btn float-right px-4 mt-4"
      >
        {{ "Next" }}
      </button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import LegalWriter from "@/store/LegalWriter.js";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      // important fields
      selectedType: this.typeValue("ii"),
      country: "",

      lawyer_name: "",
      law_firm_name: "",
      law_firm_phone_number: "",
      law_firm_email: "",
      law_firm_address: ["", "", ""],

      sender_name: "",
      sender_type: "",
      sender_address: ["", "", ""],

      receiver_name: "",
      receiver_type: "",
      receiver_address: ["", "", ""],

      letter_date: null, // Use a Date object to store the date

      // custom fields
      business_info: "",
      creative: "",
      technology: "",

      international_category: "Singapore",
      international_category_to: "Malaysia",

      partnership_description: "", // item desc?
      board_description: "", // item desc?

      product_type: "",
      project_description: "", // item desc?
      product_description: "", // item desc?
      option_duration: "",
      loan_purpose: "",
      movie_name: "",

      ipa_description: "",
      business_description: "",
      constructor_description: "",

      // additional fields
      item_description: "",
      condition: "",
      item: "",
      quantity: "",
      additional_info: "",
      from: "",
      to: "",
      from_to_category: [],

      chosenCurrencyType3: "",
      price3: "",
      chosenCurrencyType2: "",
      price2: "",
      chosenCurrencyType: "",
      price: "",

      // support data
      countries: [
        "United States of America",
        "United Kingdom",
        "Australia",
        "Canada",
        "New Zealand",
        "Singapore",
        "Malaysia",
      ],

      sender_types: ["Mr", "Ms", "Mrs"],
      receiver_types: ["Mr", "Ms", "Mrs"],

      products: [
        "Book",
        "Literature",
        "Film",
        "Music",
        "Song",
        "Artwork",
        "Photographs",
      ],
      general_products: [
        "Automotive Parts and Accessories",
        "Software and Technology Products",
        "Industrial Equipment and Machinery",
        "Pharmaceutical and Medical Products",
        "Food and Beverages",
        "Consumer Goods",
      ],

      currency_type: [
        { name: "US Dollar (USD $)", id: "US$", flag: "us" },
        { name: "British Pound (£)", id: "£", flag: "gb" },
        { name: "Australian Dollar (AU $)", id: "AU$", flag: "au" },
        { name: "Canadian Dollar (CA $)", id: "CA$", flag: "ca" },
        { name: "New Zealand Dollar (NZ $)", id: "NZ$", flag: "nz" },
        { name: "Singapore Dollar (SGD $)", id: "S$", flag: "sg" },
        { name: "Malaysian Ringgit (RM)", id: "RM", flag: "my" },
      ],

      from_international_category: [
        "United States of America",
        "United Kingdom",
        "Australia",
        "Canada",
        "New Zealand",
        "Singapore",
        "Malaysia",
      ],
      to_international_category: [
        "United States of America",
        "United Kingdom",
        "Australia",
        "Canada",
        "New Zealand",
        "Singapore",
        "Malaysia",
      ],

      // // dep
      // title: "",
      // selectedCreativeTechnology: "Individual - Individual",
      // selectedSalesPurchase: "Individual - Individual",
      // selectedNDA: "Individual - Individual",
      // selectedCM: "Individual - Individual",

      // option_fee: "",
      // movie_budget: "",
      // company_name: "",
      // company_field: "",

      /* Form Construction Syntax
       *
       * In @form_fields > query.category > query.sub_category:
       * {
       *   [1] Type Options: [ <options> ]
       *       // Options: 'ii', 'io', 'oi', 'oo'
       *       // Extra: 'pi', 'po'
       *       // this field is optional
       *
       *   [2] '...'
       *       // generate fields for
       *          Jurisdiction,
       *          Lawyer Name,
       *          LawFirm Details
       *
       *   [3] Define Parties: [ <party A>, <party B> ] / [ <single party> ]
       *       // generate fields for both parties
       *       // generate only for @this.sender & related fields
       *          when the second party is omitted
       *
       *   [4] '..'
       *       // generate field for Agreement Date
       *
       *   [5] Custom Fields: <any>
       *       // kindly put in the field name
       *          & write corresponding element in <template>
       *       // this field is optional
       *
       *   [6] 'Qty'
       *       // bind to @this.quantity
       *       // this field is optional
       *
       *   [7] @this.item_description: <any>
       *       // kindly put in the field name
       *          & update corresponding element in <template>
       *       // this field is optional
       *
       *   [8] @this.item: <any>
       *       // kindly put in the field name
       *          & update corresponding element in <template>
       *       // this field is optional
       *
       *   [9] @this.condition: <any>
       *       // kindly put in the field name
       *          & update corresponding element in <template>
       *       // this field is optional
       *
       *   [10] 'From-To' / 'From-To!'
       *        // bind to @this.from / @this.to
       *        // this field is optional
       *        // the '!' variant only displays
       *           the sender and receiver in the similar format
       *           but does not allow changing of sender and receiver
       *
       *   [11] ... / '$$<any>' / '$<any>'
       *        // bind to @this.price<#> &
       *           @this.chosenCurrencyType<#>
       *        // this field is optional
       *
       *   [12] '+'
       *        // bind to @this.additional_info
       *        // this field is optional
       * }
       *
       * To put different placeholders:
       * In  @form_fields > query.category >
       *
       * extra: {
       *
       *   itemDesc_placeholder: {...}
       *
       *   condition_placeholder: {...}
       *
       *   addInfo_placeholder: {...}
       *
       *   // Define custom placeholders for custom fields
       *   <any>_placeholder: {...}
       * }
       */
      form_fields: {
        Partnership: {
          "Small Businesses": [
            "...",
            ["Party A", "Party B"],
            "..",
            "Business Information",
          ],
          "Creative Partnerships": [
            ["ii", "oo"],
            "...",
            ["Party A", "Party B"],
            "..",
            "Creative Field",
            "Business Information",
          ],
          "Real Estate Ventures": [
            "...",
            ["Party A", "Party B"],
            "..",
            "Business Information",
          ],
          "Technology Ventures": [
            ["ii", "oo"],
            "...",
            ["Party A", "Party B"],
            "..",
            "Technology Field",
            "Business Information",
          ],
          "Join Ventures": [
            "...",
            ["Party A", "Party B"],
            "..",
            "Business Information",
          ],
        },

        "Sales and Purchase": {
          extra: {
            condition_placeholder: {
              "Subscription Agreement": "Duration of Subscription",
            },

            addInfo_placeholder: {
              "Real Estate Purchase Businesses":
                "Add any financing arrangements or contingencies, such as mortgage financing or leaseback agreements",
              "Stock Purchase Agreement":
                "Add any financing arrangements or contingencies, such as mortgage financing or leaseback agreements",
              "Subscription Agreement":
                "Add any additional details regarding the subscription or usage limit and restrictions",
              "Asset Purchase":
                "Add any additional details about allocation of purchase price among the specific assets being purchased, such as equipment, inventory, intellectual property, and any associated rights or licenses",
              "Business Purchase":
                "Add any additional details about the value attributed to the reputation, customer relationships, and other intangible assets associated with the business/ treatment of employees and any applicable employee transfer or termination obligations",
            },
          },

          "Real Estate Purchase Businesses": [
            ["ii", "oi"],
            "...",
            ["Seller", "Buyer"],
            "..",
            "Description of Property",
            "Condition of Property",
            "$Price",
            "+",
          ],
          "Stock Purchase Agreement": [
            ["ii", "oi"],
            "...",
            ["Seller", "Buyer"],
            "..",
            "Description of Stock/Share",
            "Qty",
            "$Total price of Stocks/Shares",
            "+",
          ],
          "Subscription Agreement": [
            ["ii", "oi"],
            "...",
            ["Seller", "Buyer"],
            "..",
            "Description of Service",
            "Service/Package Name",
            "Description of Subscription",
            "$Price",
            "+",
          ],
          International: [
            ["ii", "oi"],
            "...",
            ["Seller", "Buyer"],
            "..",
            "International",
            "Description of Goods",
            "Item",
            "Qty",
            "Condition",
            "$Price",
          ],
          "Asset Purchase": [
            ["ii", "oi"],
            "...",
            ["Seller", "Buyer"],
            "..",
            "Description of Asset",
            "$Price",
            "+",
          ],
          "Business Purchase": [
            ["ii", "oi"],
            "...",
            ["Seller", "Buyer"],
            "..",
            "Description of Business",
            "Item",
            "Qty",
            "Condition",
            "$Price",
            "+",
          ],
        },

        "Non-Disclosure Agreement": {
          extra: {
            itemDesc_placeholder: {
              Employee: "Describe the job scope",
              "Vendor/Supplier": "Describe the item type",
              Investor: "Describe the investment type",
              Consultant: "Describe the consultancy type",
              "Non-Compete": "Describe the business type",
            },

            addInfo_placeholder: {
              Unilateral:
                "Describe the nature of the relationship (eg: international/ partnership/etc)",
              Mutual:
                "Describe the nature of the relationship (eg: international/ partnership/etc)",
              Partnership:
                "Add any additional information related to the partnership",
              "Board Members":
                "Add any additional information related to the board",
            },
          },

          Employee: [
            "...",
            ["Employer", "Employee"],
            "..",
            "Job Description",
            "From-To",
          ],
          "Vendor/Supplier": [
            ["ii", "oi", "oo"],
            "...",
            ["Vendor", "Client"],
            "..",
            "Item Description",
            "From-To",
          ],
          Investor: [
            ["ii", "io", "oo"],
            "...",
            ["Investor", "Investee"],
            "..",
            "Investment Description",
            "From-To",
          ],
          Consultant: [
            "...",
            ["Consultant", "Client"],
            "..",
            "Consultancy Description",
            "From-To",
          ],
          "Non-Compete": [
            ["ii", "oo"],
            "...",
            ["Party A", "Party B"],
            "..",
            "Description of Business",
            "From-To",
          ],
          Unilateral: [
            ["ii", "io", "oi", "oo"],
            "...",
            ["Party A", "Party B"],
            "..",
            "Description of Business",
            "+",
            "From-To",
          ],
          Mutual: [
            ["ii", "io", "oi", "oo"],
            "...",
            ["Party A", "Party B"],
            "..",
            "Description of Business",
            "+",
            "From-To",
          ],
          Partnership: [
            ["ii", "io", "oi", "oo"],
            "...",
            ["Party A", "Party B"],
            "..",
            "Partnership",
            "+",
            "From-To",
          ],
          "Board Members": [
            "...",
            ["Board", "Member"],
            "..",
            "Board Description",
            "+",
            "From-To!",
          ],
        },

        "Creative Media": {
          extra: {
            productDesc_placeholder: {
              "Option Agreement":
                "Provide a detailed description of the creative project being optioned, including its title, concept, genre, and any relevant details that define the scope and nature of the project",
              "Development Agreement":
                "Provide a detailed description of the creative project being developed, including its title, concept, genre, and any relevant details that define the scope and nature of the project",
              "Production Agreement":
                "Provide a detailed description of the creative project being produced, including its title, concept, genre, and any relevant details that define the scope and nature of the project",
              "License Agreement":
                "Provide a detailed description of the creative project being licensed, including its title, format, relevant metadata, and any other necessary details that define the content and its scope",
              "Talent Agreement":
                "Provide a detailed description of the creative media project in which the Artist will be involved. Include the project's title, genre, format, and any other relevant details that define the scope and nature of the project",
              "Syndication Agreement":
                "Provide a detailed description of the creative project being licensed, including its title, format, relevant metadata, and any other necessary details that define the content and its scope",
              "Distribution Agreement":
                "Provide a detailed description of the creative product to be distributed, including its title, concept, genre, and any relevant details that define the scope and nature of the product",
              "Screenplay Writer Commission Agreement":
                "Provide a detailed description of the film or audiovisual project for which the Writer will be creating the screenplay. Include the title, genre, format, and any other relevant details that define the project",
              "Pro-Producer Distributor Agreement":
                "Provide a detailed description of the creative media project to be distributed, including its title, genre, format, and any other relevant details that define the project",
            },

            itemDesc_placeholder: {
              "Pro-Producer Distributor Agreement":
                "Provide a detailed description of the creative media project to be distributed, including its title, genre, format, and any other relevant details that define the project",
              "Loan of Art Agreement":
                "Provide a detailed description of the artwork being loaned, including the title, artist, medium, dimensions, condition, and any other relevant details that define the artwork",
            },
          },

          "Option Agreement": [
            ["ii", "io", "oi", "oo"],
            "...",
            ["Optionor", "Optionee"],
            "..",
            "Product Type",
            "Project Description",
            "$$Option Fee",
            "Option Duration",
            "$Purchase Price",
          ],
          "Development Agreement": [
            ["ii", "io", "oi", "oo"],
            "...",
            ["Producer", "Developer"],
            "..",
            "Product Type",
            "Project Description",
            "$Compensation Price",
          ],
          "Production Agreement": [
            ["pi", "po"],
            "...",
            ["Producer"],
            "..",
            "Product Type",
            "Project Description",
            "$Compensation Price",
          ],
          "License Agreement": [
            ["ii", "io", "oi", "oo"],
            "...",
            ["Licensor", "Licensee"],
            "..",
            "Product Type",
            "Project Description",
            "$Compensation Price",
          ],
          "Talent Agreement": [
            ["ii", "oi"],
            "...",
            ["Producer", "Artist"],
            "..",
            "Product Type",
            "Project Description",
          ],
          "Syndication Agreement": [
            ["ii", "io", "oi", "oo"],
            "...",
            ["Licensor", "Licensee"],
            "..",
            "Product Type",
            "Project Description",
            "$Compensation Price",
          ],
          "Distribution Agreement": [
            ["oo", "oi"],
            "...",
            ["Distributor", "Creator"],
            "..",
            "Product Type",
            "Product Description",
            "Qty",
            "$Price",
          ],
          "Pro-Producer Distributor Agreement": [
            ["ii", "io", "oi", "oo"],
            "...",
            ["Procuder", "Distributor"],
            "..",
            "Product Type",
            "Product Description",
            "$Compensation Price",
          ],
          "Film Production Agreement": [
            ["ii", "io", "oi", "oo"],
            "...",
            ["Producer", "Filmmaker"],
            "..",
            "Movie Name",
            "$$$Movie Budget",
            "$Producer Compensation",
          ],
          "Loan of Art Agreement": [
            ["ii", "io"],
            "...",
            ["Lender", "Borrower"],
            "..",
            "Artwork Description",
            "Loan Purpose",
            "$Compensation Price",
          ],
          "Screenplay Writer Commission Agreement": [
            ["ii", "oi"],
            "...",
            ["Commissioning Party", "Writer"],
            "..",
            "Project Description",
            "$Commission Amount",
          ],
        },

        Others: {
          extra: {
            itemDesc_placeholder: {
              "Sponsorship Agreement": "Nature of the sponsorship",
            },
          },

          "Intellectual Property Assignment Agreement": [
            "...",
            ["Owner", "Recipient"],
            "..",
            "Description of Goods",
            "$Compensation Amount",
          ],
          "Distribution Agreement": [
            "...",
            ["Distributor", "Manufacturer"],
            "..",
            "Product Type",
            "Description of Product",
            "Qty",
            "$Price",
          ],
          "Franchise Agreement": [
            ["ii", "io", "oi", "oo"],
            "...",
            ["Franchisor", "Franchisee"],
            "..",
            "Business Description",
          ],
          "Manufacturing Agreement": [
            ["ii", "io", "oi", "oo"],
            "...",
            ["Manufacturer", "Buyer"],
            "..",
            "Product Description",
            "Qty",
            "$Price",
          ],
          "Consignment Agreement": [
            ["ii", "io", "oi", "oo"],
            "...",
            ["Consignor", "Consignee"],
            "..",
            "Product Description",
            "Qty",
            "$Price",
          ],
          "Independent Constructor Agreement": [
            ["ii", "io"],
            "...",
            ["Constructor", "Client"],
            "..",
            "Constructor Description",
          ],
          "Construction Contract Acceleration Agreement": [
            ["io", "oo"],
            "...",
            ["Client", "Constructor"],
            "..",
            "Project Description",
          ],
          "Sales Agency Agreement": [
            ["io", "oo"],
            "...",
            ["Client", "Sales Agency"],
            "..",
            "Product/Service Description",
          ],
          "Web Design Agreement": [
            ["ii", "io", "oi", "oo"],
            "...",
            ["Designer", "Client"],
            "..",
            "Web Design Description",
          ],
          "Payment Agreement": [
            ["ii", "io", "oi", "oo"],
            "...",
            ["Beneficiary", "Debtor"],
            "..",
            "$Debt Amount",
          ],
          "Sponsorship Agreement": [
            ["ii", "io", "oi", "oo"],
            "...",
            ["Sponsor", "Sponsored Party"],
            "..",
            "Sponsorship Description",
          ],
        },
      },
    };
  },
  computed: {
    maxDate() {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0); // Set time to the beginning of the day
      return currentDate;
    },
  },
  methods: {
    typeValue(t) {
      if (t[0] === "p") {
        return "Producer - " + (t[1] === "i" ? "Individual" : "Organization");
      } else {
        return (
          (t[0] === "i" ? "Individual" : "Organization") +
          " - " +
          (t[1] === "i" ? "Individual" : "Organization")
        );
      }
    },

    changeFromTo() {
      this.to = this.from == this.from_to_category[0]
        ? this.from_to_category[1]
        : this.from_to_category[0];
    },

    changeLetterType() {
      this.sender_type = "";
      this.receiver_type = "";
    },

    nextStep() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const CAT = this.$route.query.category;
          const SUB = this.$route.query.sub_category;

          var final_from = "";
          var final_to = "";

          //
          var composeType =
            "agreement_" +
            {
              Partnership: "partnership",
              "Sales and Purchase": "snp",
              "Non-Disclosure Agreement": "nda",
            }[CAT];

          if (CAT == "Creative Media" || CAT == "Others") {
            composeType = "others";
          }

          // separate conversion for different categories
          var letterType = "";
          var type = "default";
          if (CAT == "Partnership") {
            letterType = SUB.toLowerCase().replace(/ /g, "_");

            if (
              ["Creative Partnerships", "Technology Ventures"].includes(
                this.$route.query.sub_category
              )
            ) {
              type = "shorten";
            } else {
              type = "none";
            }
          } else if (CAT == "Sales and Purchase") {
            letterType = SUB.toLowerCase().split(" ");

            if (letterType.length > 1) {
              letterType.splice(letterType.length - 1);
            }
            letterType = letterType.join("_");
          } else if (CAT == "Non-Disclosure Agreement") {
            letterType =
              {
                Employee: "employer",
                "Vendor/Supplier": "vendor",
                "Non-Compete": "noncompete",
                Unilateral: "uni",
                "Board Members": "board_organization_individual",
              }?.[SUB] ?? SUB.toLowerCase();

            if (["Employee", "Board Members", "Consultant"].includes(SUB)) {
              type = "none";
            } else {
              type = "default";
            }
          } else if (CAT == "Creative Media") {
            letterType = SUB == "Distribution Agreement" ? "" : "cm_";
            letterType +=
              {
                "Pro-Producer Distributor Agreement": "producer_distributor",
                "Film Production Agreement": "film_production",
                "Screenplay Writer Commission Agreement": "writer",
              }?.[SUB] ?? SUB.toLowerCase().split(" ")[0];
          } else if (CAT == "Others") {
            letterType =
              {
                "Intellectual Property Assignment Agreement":
                  "ipa_agreement_individual_individual",
                "Distribution Agreement":
                  "distribution_organization_organization",
                "Independent Constructor Agreement": "independant_constructor", // typo!
                "Construction Contract Acceleration Agreement":
                  "construction_acceleration",
                "Sales Agency Agreement": "sales_agency",
                "Web Design Agreement": "web_design",
              }?.[SUB] ?? SUB.toLowerCase().split(" ")[0];

            if (
              SUB == "Intellectual Property Assignment Agreement" ||
              SUB == "Distribution Agreement"
            ) {
              type = "none";
            }
          }

          switch (type) {
            // used in cat: 'Partnership'
            // for shorten Type ( 'ii'/'oo' only )
            case "shorten":
              switch (this.selectedType) {
                case this.typeValue("ii"):
                  letterType += "_individual";
                  break;

                case this.typeValue("oo"):
                  letterType += "_organizations";
                  this.sender_type = "";
                  this.receiver_type = "";
                  break;
              }
              break;

            // general Type ( 'ii' 'io' 'oi' 'oo' )
            case "default":
              var org = "_organization";
              switch (this.selectedType) {
                case this.typeValue("ii"):
                  letterType += "_individual_individual";
                  break;

                case this.typeValue("io"):
                  letterType += "_individual" + org;
                  this.receiver_type = "";
                  break;

                case this.typeValue("oi"):
                  letterType += org + "_individual";
                  this.sender_type = "";
                  break;

                case this.typeValue("oo"):
                  letterType += org + org;
                  this.sender_type = "";
                  this.receiver_type = "";
              }
              break;
          }

          if (!Array.isArray(this.form_fields[CAT][SUB][0])) {
            this.selectedType = "";
          }

          // additional condition check
          var tempJuri = "";
          var newJuri = "";
          if (this.country === "United States of America") {
            tempJuri = "USA";
            newJuri = "United States";
          } else {
            tempJuri = this.country;
            newJuri = this.country;
          }

          if (SUB == "Real Estate Ventures") {
            letterType += "_individual";
          }

          if (
            SUB == "Subscription Agreement" ||
            SUB == "Real Estate Purchase Businesses"
          ) {
            this.quantity = "";
          } else {
            this.quantity = this.quantity;
          }

          if (SUB == "Real Estate Purchase Businesses") {
            this.item = "";
          } else {
            this.item = this.item;
          }

          if (SUB == "International") {
            final_from = this.international_category;
            final_to = this.international_category_to;
          }

          if (CAT == "Non-Disclosure Agreement") {
            final_from = this.from;
            final_to = this.to;
          }

          var obj = {
            info: {
              jurisdiction: newJuri,
              law_firm_name: this.law_firm_name,
              law_firm_phone_number: this.law_firm_phone_number,
              law_firm_email: this.law_firm_email,
              law_firm_address: this.law_firm_address.join("\n"),
              lawyer: this.lawyer_name,

              sender_name: this.sender_name,
              sender_title: this.sender_type,
              sender_address: this.sender_address.join("\n"),

              receiver_name: this.receiver_name,
              receiver_title: this.receiver_type,
              receiver_address: this.receiver_address.join("\n"),

              date: this.letter_date,
              today: new Intl.DateTimeFormat(
                Intl.DateTimeFormat().resolvedOptions().locale
              ).format(new Date()),

              creative_field: this.creative,
              technology: this.technology,
              business_info: this.business_info,
              option_duration: this.option_duration,
              movie_name: this.movie_name,
              loan_purpose: this.loan_purpose,

              item_description: this.item_description,
              item: this.item,
              quantity: this.quantity,
              condition: this.condition,
              additional_info: this.additional_info,

              price: this.chosenCurrencyType.id + this.price,
              option_fee: this.chosenCurrencyType2.id + this.price2,
              movie_budget: this.chosenCurrencyType3.id + this.price3,

              from: final_from,
              to: final_to,

              isCM: CAT == "Creative Media" ? "creative_media" : "",
              creative_media: SUB == "Distribution Agreement" ? true : null,
              job_description: this.item_description,
              investment_description: this.item_description,
              consultant_type: this.item_description,
              business_description:
                this.business_description.length > 0
                  ? this.business_description
                  : this.item_description,

              product_type: this.product_type,

              partnership_description: this.partnership_description,
              board_description: this.board_description,
              project_description: this.project_description,
              product_description: this.product_description,

              ipa_description: this.ipa_description,
              constructor_description: this.constructor_description,
            },
            compose_type: composeType,
            letter_type: letterType,
            location_id: JSON.parse(localStorage.locations).find(
              (x) => x.title === tempJuri
            ).id,
            language: "en",
          };
          // console.log(obj); return;

          LegalWriter.CreateLOD(obj)
            .then((response) => {
              localStorage.removeItem("lod_first_data");

              this.$router.push({
                name: "LegalWriterEdit",
                params: {
                  id: response.data.data.id,
                },
                query: {
                  category: CAT,
                },
              });
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          // console.log(this.errors);
          this.$toast.error("Please fill in all the information");
        }
      });
    },
  },

  mounted() {
    // [!!] raise warnings [vee-validate]
    var refs = [
      this.$refs.mySelect,
      this.$refs.mySelect2,
      this.$refs.mySelect3,
    ];

    // sizing for '..$<any>' fields
    const cash = "$";
    var regExStr = "^";
    for (var i = 0; i < refs.length; i++) {
      regExStr += cash;
      if (
        this.form_fields?.[this.$route.query.category]?.[
          this.$route.query.sub_category
        ].includes((item) => new RegExp(regExStr).test(item)) &&
        refs[i] !== null
      ) {
        refs[i].$el
          .querySelector(".vs__open-indicator")
          .setAttribute("viewBox", "0 0 18 10");
      }
    }
  },

  created() {
    this.lawyer_name = JSON.parse(localStorage.userInfo).name;
    this.law_firm_email = JSON.parse(localStorage.userInfo).email;
    if (
      JSON.parse(localStorage.userInfo).extra_info != null &&
      JSON.parse(localStorage.userInfo).extra_info.length != 0
    ) {
      this.law_firm_name = JSON.parse(
        localStorage.userInfo
      ).extra_info.company_name;
      this.law_firm_phone_number = JSON.parse(
        localStorage.userInfo
      ).extra_info.contact_number;
      this.law_firm_address = JSON.parse(
        localStorage.userInfo
      ).extra_info.address.split("\n");
    }

    if (this.$route.query.sub_category == "Distribution Agreement") {
      this.selectedType = this.typeValue("oo");
    } else if (
      this.$route.query.sub_category ==
      "Construction Contract Acceleration Agreement"
    ) {
      this.selectedType = this.typeValue("oo");
    } else if (this.$route.query.sub_category == "Sales Agency Agreement") {
      this.selectedType = this.typeValue("io");
    }

    if (
      this.form_fields?.[this.$route.query.category]?.[
        this.$route.query.sub_category
      ].includes("From-To") ||
      this.form_fields?.[this.$route.query.category]?.[
        this.$route.query.sub_category
      ].includes("From-To!")
    ) {
      var form =
        this.form_fields[this.$route.query.category][
          this.$route.query.sub_category
        ];

      // search for the party name array from form_fields
      // usually at index 1 if there is no type selection in the form
      // or at index 2 if type selection take the first position in the array
      for (var i = 1; i < 3; i++) {
        if (Array.isArray(form[i])) {
          this.from_to_category = form[i];
          [this.from, this.to] = form[i];
          break;
        }
      }
    }
  },
};
</script>

<style scoped>
@media screen and (max-width: 767.98px) {
  .general_container {
    margin: 1rem 2rem 2rem 2rem !important;
  }
}
.general_container {
  margin: 1rem 5rem 2rem 5rem;
}

.col,
.col-3,
.col-7,
.col-12 {
  margin-top: 1rem;
}

.input-address {
  margin: 1rem 0rem;
}
</style>
