<template>
  <div
    class="general_container"
    v-if="this.$route.query.category == 'Insurance Claim'"
  >
    <div class="row">
      <div class="col-sm-12 col-md-3 align-self-center">
        <b>{{ "Claim Number" }}</b>
        <span
          id="tooltip-target-1"
          class="text-danger font-weight-bold cursor-pointer mx-1"
        >*</span>
        <b-tooltip
          target="tooltip-target-1"
          triggers="hover"
          placement="top"
          variant="secondary"
          id="tooltip1"
          title="Required"
        >
        </b-tooltip>
      </div>

      <div class="col align-self-center">
        <b-form-input
          v-model="claim_number"
          placeholder="Insurance Claim Number"
          autocorrect="off"
          autocomplete="off"
          v-validate="'required'"
          name="Insurance Claim Number"
          type="text"
        ></b-form-input>
        <span class="text-danger small">
          {{ errors.first("Insurance Claim Number") }}
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-3 align-self-center">
        <b>{{ "Policy Number" }}</b>
        <span
          id="tooltip-target-2"
          class="text-danger font-weight-bold cursor-pointer mx-1"
        >*</span>
        <b-tooltip
          target="tooltip-target-2"
          triggers="hover"
          placement="top"
          variant="secondary"
          id="tooltip1"
          title="Required"
        >
        </b-tooltip>
      </div>

      <div class="col align-self-center">
        <b-form-input
          v-model="policy_number"
          placeholder="Policy Number"
          autocorrect="off"
          autocomplete="off"
          v-validate="'required'"
          name="Policy Number"
          type="text"
        ></b-form-input>
        <span class="text-danger small">
          {{ errors.first("Policy Number") }}
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-3 align-self-center">
        <b>{{ "Supporting Document" }}</b>
      </div>

      <div class="col align-self-center">
        <b-form-input
          v-model="supporting_document"
          placeholder="Enter the name of Supporting Document. \nExample: Signed Policy, Medical Certificate & etc."
          autocorrect="off"
          autocomplete="off"
          name="Supporting Document"
        ></b-form-input>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-3 align-self-center">
        <b>{{ "Damages/ Incident information" }} </b>
        <span
          id="tooltip-target-3"
          class="text-danger font-weight-bold cursor-pointer mx-1"
        >*</span>
        <b-tooltip
          target="tooltip-target-3"
          triggers="hover"
          placement="top"
          variant="secondary"
          id="tooltip1"
          title="Required"
        >
        </b-tooltip>
      </div>

      <div class="col align-self-center">
        <b-form-input
          v-model="damage_cause"
          placeholder="Example: Hospitalised for 10 days."
          autocorrect="off"
          autocomplete="off"
          v-validate="'required'"
          name="Damage Caused"
          type="text"
        ></b-form-input>
        <span class="text-danger small">
          {{ errors.first("Damage Caused") }}
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-3 align-self-center">
        <b>{{ "Policy Information" }} </b>
        <span
          id="tooltip-target-4"
          class="text-danger font-weight-bold cursor-pointer mx-1"
        >*</span>
        <b-tooltip
          target="tooltip-target-4"
          triggers="hover"
          placement="top"
          variant="secondary"
          id="tooltip1"
          title="Required"
        >
        </b-tooltip>
      </div>

      <div class="col align-self-center">
        <b-form-input
          v-model="policy_information"
          placeholder="Example: Policy given by Agent named Rose."
          autocorrect="off"
          autocomplete="off"
          v-validate="'required'"
          name="Policy Information"
          type="text"
        ></b-form-input>
        <span class="text-danger small">
          {{ errors.first("Policy Information") }}
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-3 align-self-center">
        <b>{{ "Claim Amount" }} </b>
        <span
          id="tooltip-target-5"
          class="text-danger font-weight-bold cursor-pointer mx-1"
        >*</span>
        <b-tooltip
          target="tooltip-target-5"
          triggers="hover"
          placement="top"
          variant="secondary"
          id="tooltip1"
          title="Required"
        >
        </b-tooltip>
      </div>

      <!-- initial select -->
      <!-- <div class="col-xs-12 col-md-3 col-lg-4 col-xl-2">
        <select class="form-select w-100 h-100 rounded" id="inputGroupSelect04"
          aria-label="Example select with button addon" v-model="chosenCurrencyType" v-validate="'required'"
          name="Currency Type">
          <option :value="type.id" :key="index" v-for="(type, index) in currency_type">
            {{ type.name }}
          </option>
        </select>
      </div> -->

      <div class="col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <v-select
          ref="mySelect"
          v-model="chosenCurrencyType"
          v-validate="'required'"
          :options="currency_type"
          name="Currecy Type"
          label="name"
          class="new-styles"
        >
          <template #option="option">
            <span :class="'fi fi-' + option.flag"></span>
            <span class="mx-3">{{ option.name }}</span>
          </template>
        </v-select>
      </div>

      <div class="col align-self-center">
        <b-form-input
          v-model="outstanding_amount"
          placeholder="Amount"
          autocorrect="off"
          autocomplete="off"
          v-validate="'required|numeric'"
          name="Outstanding Amount"
          type="text"
          id="outAmountInput"
        ></b-form-input>
      </div>
      
      <div class="col-12 d-flex flex-row">
        <div id="Amount-spacing"></div>
        <span class="text-danger small">
          {{ errors.first("Outstanding Amount") }}
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-3 align-self-center">
        <b>{{ "Coverage Explanation" }}</b>
        <span
          id="tooltip-target-6"
          class="text-danger font-weight-bold cursor-pointer mx-1"
        >*</span>
        <b-tooltip
          target="tooltip-target-6"
          triggers="hover"
          placement="top"
          variant="secondary"
          id="tooltip1"
          title="Required"
        >
        </b-tooltip>
      </div>

      <div class="col align-self-center">
        <b-form-textarea
          id="textarea"
          v-model="coverage_exp"
          placeholder="Example: Policy will cover up to 15 days of hospitalization."
          autocorrect="off"
          autocomplete="off"
          rows="3"
          max-rows="6"
          v-validate="'required'"
          name="Coverage Explanation"
          type="text"
        ></b-form-textarea>
        <span class="text-danger small">
          {{ errors.first("Coverage Explanation") }}
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-3 align-self-center">
        <b>{{ "Deadline" }}</b>
        <span
          id="tooltip-target-7"
          class="text-danger font-weight-bold cursor-pointer mx-1"
        >*</span>
        <b-tooltip
          target="tooltip-target-7"
          triggers="hover"
          placement="top"
          variant="secondary"
          id="tooltip1"
          title="Required"
        >
        </b-tooltip>
      </div>

      <div class="col align-self-center">
        <b-form-datepicker
          id="example-datepicker"
          v-model="deadline"
          calendar-width="100%"
          v-validate="'required'"
          name="Deadline"
          type="text"
        ></b-form-datepicker>
        <span class="text-danger small">
          {{ errors.first("Deadline") }}
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-3 align-self-center">
        <b>{{ "Additional Details" }}</b>
      </div>

      <div class="col align-self-center">
        <b-form-textarea
          id="textarea"
          v-model="additional_details"
          placeholder="Add additional important details that you want to be notified/ include in the letter."
          autocorrect="off"
          autocomplete="off"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col">
        <button
          @click="constructLetter"
          class="btn-primary btn px-2 float-right mx-2"
        >
          {{ "Construct" }}
        </button>

        <button
          @click="goBack"
          class="btn-primary-outlined btn px-2 float-right mx-2"
        >
          {{ "Back" }}
        </button>
      </div>
    </div>
  </div>

  <div v-else class="general_container">
    <div class="row">
      <div class="col-sm-12 col-md-3 align-self-center">
        <b>
          {{ this.$route.query.category == 'Outstanding Payment'
            ? "Outstanding Amt."
            :this.$route.query.category == 'Property Damage Claim'
            ? "Claim Amount"
            : "ERROR" }}
        </b>
        <span
          id="tooltip-target-8"
          class="text-danger font-weight-bold cursor-pointer mx-1"
        >*</span>
        <b-tooltip
          target="tooltip-target-8"
          triggers="hover"
          placement="top"
          variant="secondary"
          id="tooltip1"
          title="Required"
        >
        </b-tooltip>
      </div>

      <!-- initial select -->
      <!-- <div class="col-xs-12 col-md-3 col-lg-4 col-xl-2 align-self-center" id="outstandingAmountCurrency">
        <select class="form-select w-100 rounded" id="inputGroupSelect04" aria-label="Example select with button addon"
          v-model="chosenCurrencyType" v-validate="'required'" name="Currency Type">
          <option :value="type.id" :key="index" v-for="(type, index) in currency_type">
            {{ type.name }}
          </option>
        </select>
      </div> -->

      <div class="col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <v-select
          ref="mySelect"
          v-model="chosenCurrencyType"
          v-validate="'required'"
          :options="currency_type"
          label="name"
          class="new-styles"
        >
          <template #option="option">
            <span :class="'fi fi-' + option.flag"></span>
            <span class="mx-3">{{ option.name }}</span>
          </template>
        </v-select>
      </div>

      <div class="col align-self-center">
        <b-form-input
          v-model="outstanding_amount"
          placeholder="Amount"
          autocorrect="off"
          autocomplete="off"
          v-validate="'required|numeric'"
          name="Outstanding Amount"
          type="text"
          id="outAmountInput"
        ></b-form-input>
      </div>

      <div class="col-12 d-flex flex-row">
        <div id="Amount-spacing"></div>
        <span class="text-danger small">
          {{ errors.first("Outstanding Amount") }}
        </span>
      </div>
    </div>

    <div class="row" v-if="this.$route.query.category == 'Outstanding Payment'">
      <div class="col-sm-12 col-md-3 align-self-center">
        <b>{{ "Agreement" }}</b>
        <span
          id="tooltip-target-9"
          class="text-danger font-weight-bold cursor-pointer mx-1"
        >*</span>
        <b-tooltip
          target="tooltip-target-9"
          triggers="hover"
          placement="top"
          variant="secondary"
          id="tooltip1"
          title="Required"
        >
        </b-tooltip>
      </div>

      <div class="col align-self-center">
        <b-form-textarea
          id="textarea"
          v-model="Agreement"
          :placeholder="
            this.$route.query.category == 'Outstanding Payment'
              ? 'Explain briefly what are the conditions both parties agreed upon. \nExample: Debtor promised to pay back the full amount in 12 months from now.'
              : 'Enter the damage cause.'
          "
          autocorrect="off"
          autocomplete="off"
          rows="3"
          max-rows="6"
          v-validate="'required'"
          name="Agreement"
          type="text"
        ></b-form-textarea>
        <span class="text-danger small">
          {{ errors.first("Agreement") }}
        </span>
      </div>
    </div>

    <div class="row" v-else>
      <div class="col-sm-12 col-md-3 align-self-center">
        <b>{{ "Cause" }}</b>
        <span
          id="tooltip-target-10"
          class="text-danger font-weight-bold cursor-pointer mx-1"
        >*</span>
        <b-tooltip
          target="tooltip-target-10"
          triggers="hover"
          placement="top"
          variant="secondary"
          id="tooltip1"
          title="Required"
        >
        </b-tooltip>
      </div>

      <div class="col align-self-center">
        <b-form-textarea
          id="textarea"
          v-model="damage_cause"
          :placeholder="
            this.$route.query.category == 'Outstanding Payment'
              ? 'Enter the clause number/ clause name/ clause details/ agreement terms/ in which the payment was agreed upon. \nExample: Agreed in Contract Cluase 5 of contract ABC which states ...'
              : 'Enter the damage cause.'
          "
          autocorrect="off"
          autocomplete="off"
          rows="3"
          max-rows="6"
          v-validate="'required'"
          name="Damage Caused"
          type="text"
        ></b-form-textarea>
        <span class="text-danger small">
          {{ errors.first("Damage Caused") }}
        </span>
      </div>
    </div>

    <div class="row" v-if="this.$route.query.category == 'Outstanding Payment'">
      <div class="col-sm-12 col-md-3 align-self-center">
        <b>{{ "Contract Details" }}</b>
        <span
          id="tooltip-target-11"
          class="text-danger font-weight-bold cursor-pointer mx-1"
        >*</span>
        <b-tooltip
          target="tooltip-target-11"
          triggers="hover"
          placement="top"
          variant="secondary"
          id="tooltip1"
          title="Required"
        >
        </b-tooltip>
      </div>

      <div class="col align-self-center">
        <b-form-textarea
          id="textarea"
          v-model="contract_details"
          :placeholder="
            this.$route.query.category == 'Outstanding Payment'
              ? 'Enter the clause number/ clause name/ clause details/ in which the payment was agreed upon. \nExample: Agreed in Contract Clause 5 of contract ABC which states ...'
              : 'Enter the damage cause.'
          "
          autocorrect="off"
          autocomplete="off"
          rows="3"
          max-rows="6"
          v-validate="'required'"
          name="Contract Details"
          type="text"
        ></b-form-textarea>
        <span class="text-danger small">
          {{ errors.first("Contract Details") }}
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-3 align-self-center">
        <b>{{ "Deadline" }}</b>
        <span
          id="tooltip-target-12"
          class="text-danger font-weight-bold cursor-pointer mx-1"
        >*</span>
        <b-tooltip
          target="tooltip-target-12"
          triggers="hover"
          placement="top"
          variant="secondary"
          id="tooltip1"
          title="Required"
        >
        </b-tooltip>
      </div>

      <div class="col align-self-center">
        <b-form-datepicker
          id="example-datepicker"
          v-model="deadline"
          calendar-width="100%"
          v-validate="'required'"
          name="Deadline"
          type="text"
        ></b-form-datepicker>
        <span class="text-danger small">
          {{ errors.first("Deadline") }}
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-3 align-self-center">
        <b>{{ "Additional Details" }}</b>
      </div>

      <div class="col align-self-center">
        <b-form-textarea
          id="textarea"
          v-model="additional_details"
          placeholder="Add additional important details that you want to be notified/ include in the letter."
          autocorrect="off"
          autocomplete="off"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col">
        <button
          @click="constructLetter"
          class="btn-primary btn px-2 float-right mx-2"
        >
          {{ "Construct" }}
        </button>

        <button
          @click="goBack"
          class="btn-primary-outlined btn px-2 float-right mx-2"
        >
          {{ "Back" }}
        </button>
      </div>
    </div>
  </div>

  <!-- <div class="general_container" v-else>
    <div class="row">
      <div class="col-sm-12 col-md-4 align-self-center">
        <b>{{ "Introduction and Purpose" }} </b>
      </div>

      <div class="col align-self-center">
        <b-form-textarea
          v-model="introduction_purpose"
          placeholder=""
          autocorrect="off"
          autocomplete="off"
          rows="3"
          max-rows="6"
          id="textarea"
        ></b-form-textarea>
        <small class="text-muted float-right count_length"
          >{{ introduction_purpose.length }} / 120</small
        >
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-4 align-self-center">
        <b>{{ "Partnership Details" }} </b>
      </div>

      <div class="col align-self-center">
        <b-form-textarea
          v-model="partnership_details"
          placeholder=""
          autocorrect="off"
          autocomplete="off"
          rows="3"
          max-rows="6"
          id="textarea"
        ></b-form-textarea>
        <small class="text-muted float-right count_length"
          >{{ partnership_details.length }} / 120</small
        >
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-4 align-self-center">
        <b>{{ "Partnership Structure" }} </b>
      </div>

      <div class="col align-self-center">
        <b-form-textarea
          v-model="partnership_structure"
          placeholder=""
          autocorrect="off"
          autocomplete="off"
          rows="3"
          max-rows="6"
          id="textarea"
        ></b-form-textarea>
        <small class="text-muted float-right count_length"
          >{{ partnership_structure.length }} / 120</small
        >
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-4 align-self-center">
        <b>{{ "Duration and Termination" }} </b>
      </div>

      <div class="col align-self-center">
        <b-form-textarea
          v-model="duration_termination"
          placeholder=""
          autocorrect="off"
          autocomplete="off"
          rows="3"
          max-rows="6"
          id="textarea"
        ></b-form-textarea>
        <small class="text-muted float-right count_length"
          >{{ duration_termination.length }} / 120</small
        >
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-4 align-self-center">
        <b>{{ "Capital Contributions" }} </b>
      </div>

      <div class="col align-self-center">
        <b-form-textarea
          v-model="capital_contibutions"
          placeholder=""
          autocorrect="off"
          autocomplete="off"
          rows="3"
          max-rows="6"
          id="textarea"
        ></b-form-textarea>
        <small class="text-muted float-right count_length"
          >{{ capital_contibutions.length }} / 120</small
        >
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-4 align-self-center">
        <b>{{ "Profit and Loss Sharing" }} </b>
      </div>

      <div class="col align-self-center">
        <b-form-textarea
          v-model="profit_loss_sharing"
          placeholder=""
          autocorrect="off"
          autocomplete="off"
          rows="3"
          max-rows="6"
          id="textarea"
        ></b-form-textarea>
        <small class="text-muted float-right count_length"
          >{{ profit_loss_sharing.length }} / 120</small
        >
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-4 align-self-center">
        <b>{{ "Decision-Making and Management" }} </b>
      </div>

      <div class="col align-self-center">
        <b-form-textarea
          v-model="decision_making_management"
          placeholder=""
          autocorrect="off"
          autocomplete="off"
          rows="3"
          max-rows="6"
          id="textarea"
        ></b-form-textarea>
        <small class="text-muted float-right count_length"
          >{{ decision_making_management.length }} / 120</small
        >
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-4 align-self-center">
        <b>{{ "Partnership Duties" }} </b>
      </div>

      <div class="col align-self-center">
        <b-form-textarea
          v-model="partnership_duties"
          placeholder=""
          autocorrect="off"
          autocomplete="off"
          rows="3"
          max-rows="6"
          id="textarea"
        ></b-form-textarea>
        <small class="text-muted float-right count_length"
          >{{ partnership_duties.length }} / 120</small
        >
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-4 align-self-center">
        <b>{{ "Dispute Resolution" }} </b>
      </div>

      <div class="col align-self-center">
        <b-form-textarea
          v-model="dispute_resolution"
          placeholder=""
          autocorrect="off"
          autocomplete="off"
          rows="3"
          max-rows="6"
          id="textarea"
        ></b-form-textarea>
        <small class="text-muted float-right count_length"
          >{{ dispute_resolution.length }} / 120</small
        >
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-4 align-self-center">
        <b>{{ "Confidentiality and Non-Compete" }} </b>
      </div>

      <div class="col align-self-center">
        <b-form-textarea
          v-model="confidential_non_compete"
          placeholder=""
          autocorrect="off"
          autocomplete="off"
          rows="3"
          max-rows="6"
          id="textarea"
        ></b-form-textarea>
        <small class="text-muted float-right count_length"
          >{{ confidential_non_compete.length }} / 120</small
        >
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-4 align-self-center">
        <b>{{ "Governing Law and Jurisdiction" }} </b>
      </div>

      <div class="col align-self-center">
        <b-form-textarea
          v-model="goeverning_law_jurisdiction"
          placeholder=""
          autocorrect="off"
          autocomplete="off"
          rows="3"
          max-rows="6"
          id="textarea"
        ></b-form-textarea>
        <small class="text-muted float-right count_length"
          >{{ goeverning_law_jurisdiction.length }} / 120</small
        >
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-4 align-self-center">
        <b>{{ "Amendments and Modifications" }} </b>
      </div>

      <div class="col align-self-center">
        <b-form-textarea
          v-model="amendments_modifications"
          placeholder=""
          autocorrect="off"
          autocomplete="off"
          rows="3"
          max-rows="6"
          id="textarea"
        ></b-form-textarea>
        <small class="text-muted float-right count_length"
          >{{ amendments_modifications.length }} / 120</small
        >
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-4 align-self-center">
        <b>{{ "Entire Agreement and Severability" }} </b>
      </div>

      <div class="col align-self-center">
        <b-form-textarea
          v-model="agreement_severability"
          placeholder=""
          autocorrect="off"
          autocomplete="off"
          rows="3"
          max-rows="6"
          id="textarea"
        ></b-form-textarea>
        <small class="text-muted float-right count_length"
          >{{ agreement_severability.length }} / 120</small
        >
      </div>
    </div>

    <div class="row" :key="index" v-for="(item, index) in additionalTerms">
      <div class="col-sm-12 col-md-4 align-self-center">
        <b-form-input
          v-model="item.key"
          placeholder=""
          autocorrect="off"
          autocomplete="off"
        ></b-form-input>
      </div>

      <div class="col align-self-center">
        <b-form-textarea
          id="textarea"
          v-model="item.value"
          placeholder=""
          autocorrect="off"
          autocomplete="off"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
        <small class="text-muted float-right count_length"
          >{{ item.value.length }} / 120</small
        >
      </div>

      <div class="col-1 align-self-center">
        <span @click="removeTerms(index)" class="material-icons-outlined">
          delete
        </span>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col">
        <button
          @click="addterms"
          class="btn-secondary btn px-4 float-left mx-2"
        >
          {{ "Add Terms" }}
        </button>

        <button
          @click="constructLetter"
          class="btn-primary btn px-2 float-right mx-2"
        >
          {{ "Construct" }}
        </button>

        <button
          @click="goBack"
          class="btn-primary-outlined btn px-2 float-right mx-2"
        >
          {{ "Back" }}
        </button>
      </div>
    </div>
  </div> -->
</template>

<script>
/* eslint-disable */
import LegalWriter from "@/store/LegalWriter.js";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      outstanding_amount: "",
      cost: "",
      Agreement: "",
      deadline: null, // Use a Date object to store the date
      additional_details: "",
      currency_type: [
        { name: "US Dollar (USD $)", id: "US$", flag: "us" },
        { name: "British Pound (£)", id: "£", flag: "gb" },
        { name: "Australian Dollar (AU $)", id: "AU$", flag: "au" },
        { name: "Canadian Dollar (CA $)", id: "CA$", flag: "ca" },
        { name: "New Zealand Dollar (NZ $)", id: "NZ$", flag: "nz" },
        { name: "Singapore Dollar (SGD $)", id: "S$", flag: "sg" },
        { name: "Malaysian Ringgit (RM)", id: "RM", flag: "my" },
      ],
      chosenCurrencyType: "",
      introduction_purpose: "",
      partnership_details: "",
      partnership_structure: "",
      duration_termination: "",
      capital_contibutions: "",
      profit_loss_sharing: "",
      decision_making_management: "",
      partnership_duties: "",
      dispute_resolution: "",
      confidential_non_compete: "",
      goeverning_law_jurisdiction: "",
      amendments_modifications: "",
      agreement_severability: "",
      damage_cause: "",
      contract_details: "",
      additionalTerms: [],
      claim_number: "",
      policy_number: "",
      supporting_document: "",
      policy_information: "",
      coverage_exp: "",
    };
  },
  methods: {
    addterms() {
      this.additionalTerms.push({
        key: "",
        value: "",
      });
    },
    removeTerms(index) {
      this.additionalTerms.splice(index, 1);
    },

    constructLetter() {
      // Your logic to construct the letter goes here

      this.$validator.validateAll().then((result) => {
        if (result) {
          var setValue = JSON.parse(localStorage.lod_first_data);
          var composeType = "";

          if (this.$route.query.category == "Outstanding Payment") {
            composeType = "lod_outstanding_payment";
          } else if (this.$route.query.category == "Property Damage Claim") {
            composeType = "lod_property_damage";
          } else if (this.$route.query.category == "Insurance Claim") {
            composeType = "lod_insurance";
          }

          var letterType = "";

          if (setValue.letter_type == "Individual - Individual") {
            letterType = "individual_individual";
          } else if (setValue.letter_type == "Individual - Organization") {
            letterType = "individual_organization";
          } else if (setValue.letter_type == "Organization - Individual") {
            letterType = "organization_individual";
          } else if (setValue.letter_type == "Organization - Organization") {
            letterType = "organization_organization";
          } else if (setValue.letter_type == "Auto Insurance Claims") {
            letterType = "auto_insurance_claims";
          } else if (setValue.letter_type == "Homeowner's Insurance Claims") {
            letterType = "homeowners_insurance_claims";
          } else if (setValue.letter_type == "Health Insurance Claims") {
            letterType = "health_insurance_claims";
          } else if (setValue.letter_type == "Life Insurance Claims") {
            letterType = "life_insurance_claims";
          } else if (setValue.letter_type == "Disability Insurance Claims") {
            letterType = "disability_insurance_claims";
          } else if (setValue.letter_type == "Travel Insurance Claims") {
            letterType = "travel_insurance_claims";
          }

          setValue.compose_type = composeType;
          setValue.letter_type = letterType;
          setValue.info.currency_type = this.chosenCurrencyType.id;
          setValue.info.outstanding_amount = this.outstanding_amount;
          setValue.info.out_amount =
            (this.chosenCurrencyType?.id ?? "") + this.outstanding_amount;
          setValue.info.cost =
            (this.chosenCurrencyType?.id ?? "") + this.outstanding_amount;
          setValue.info.agreement = this.Agreement;
          setValue.info.contract_details = this.contract_details;
          setValue.info.deadline = this.deadline;
          setValue.info.additional_info = this.additional_details;
          setValue.info.damage_cause = this.damage_cause;

          setValue.info.claim_number = this.claim_number;
          setValue.info.policy_number = this.policy_number;
          setValue.info.documents = this.supporting_document.split(",");
          setValue.info.claim_amount =
            (this.chosenCurrencyType?.id ?? "") + this.outstanding_amount;
          setValue.info.policy_info = this.policy_information;
          setValue.info.coverage_exp = this.coverage_exp;

          // console.log(setValue);

          LegalWriter.CreateLOD(setValue)
            .then((response) => {
              localStorage.removeItem("lod_first_data");

              this.$router.push({
                name: "LegalWriterEdit",
                params: {
                  id: response.data.data.id,
                },
                query: {
                  category: this.$route.query.category,
                },
              });
            })

            .catch((error) => {
              console.log(error);
            });
        } else {
          this.$toast.error("Please fill in all the important information");
        }
      });
    },
    goBack() {
      // Your logic to navigate back goes here
      var setValue = JSON.parse(localStorage.lod_first_data);

      setValue.info.currency_type = this.chosenCurrencyType?.id ?? "";
      setValue.info.outstanding_amount = this.outstanding_amount;
      setValue.info.out_amount =
        this.chosenCurrencyType.id + this.outstanding_amount;
      setValue.info.cost = this.chosenCurrencyType.id + this.outstanding_amount;

      setValue.info.agreement = this.Agreement;
      setValue.info.contract_details = this.contract_details;
      setValue.info.deadline = this.deadline;
      setValue.info.additional_info = this.additional_details;
      setValue.info.damage_cause = this.damage_cause;

      setValue.info.claim_number = this.claim_number;
      setValue.info.policy_number = this.policy_number;
      setValue.info.documents = this.supporting_document;
      setValue.info.claim_amount =
        this.chosenCurrencyType.id + this.outstanding_amount;
      setValue.info.policy_info = this.policy_information;
      setValue.info.coverage_exp = this.coverage_exp;

      // console.log(setValue);
      localStorage.setItem("lod_first_data", JSON.stringify(setValue));

      this.$router.push({
        name: "LegalWriterInputs",
        params: {
          step: "general_details",
        },
        query: {
          category: this.$route.query.category,
        },
      });
    },
  },
  mounted() {
    const svg = this.$refs.mySelect.$el.querySelector(".vs__open-indicator");
    if (svg) {
      svg.setAttribute("viewBox", "0 0 18 10");
    }
  },
  created() {
    var setValue = JSON.parse(localStorage.lod_first_data);

    this.chosenCurrencyType = setValue.info.currency_type;

    this.outstanding_amount = setValue.info.outstanding_amount;

    this.Agreement = setValue.info.agreement;
    this.contract_details = setValue.info.contract_details;
    this.deadline = setValue.info.deadline;
    this.additional_details = setValue.info.additional_info;

    this.damage_cause = setValue.info.damage_cause;

    this.claim_number = setValue.info.claim_number;
    this.policy_number = setValue.info.policy_number;
    this.supporting_document = setValue.info.documents;

    this.policy_information = setValue.info.policy_info;
    this.coverage_exp = setValue.info.coverage_exp;
  },
};
</script>

<style scoped>
.general_container {
  margin: 3rem 5rem;
}

.row {
  margin: 1rem 0rem;
}

.input-address {
  margin: 1rem 0rem;
}

.material-icons-outlined {
  color: grey;
  font-size: 2rem;
  cursor: pointer;
}

@media only screen and (max-width: 425px) {
  .general_container {
    margin: 0rem 0rem;
  }

  #outstandingAmountCurrency {
    margin-bottom: 10px;
  }

  /* #receiverDetailsName {
    margin-bottom: 10px;
  } */
}

@media screen and (max-width: 768px) {
  #Amount-spacing {
    display: none;
  }
}

#Amount-spacing {
  width: 25.7%
}
</style>
