import { render, staticRenderFns } from "./LetterDetail.vue?vue&type=template&id=046018b8&scoped=true"
import script from "./LetterDetail.vue?vue&type=script&lang=js"
export * from "./LetterDetail.vue?vue&type=script&lang=js"
import style0 from "./LetterDetail.vue?vue&type=style&index=0&id=046018b8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "046018b8",
  null
  
)

export default component.exports